@import "variables";

:root {
  font-size: $base-font-size;
}

body * {
  font-family: $base-font-family;
  //@media #{$breakpoint-mobile}{
  //  font-size: $responsive-font-size;
  //}
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background: var(--background-body);
  color: var(--text);
  //overflow-x: hidden;
  min-height: 100vh;
  text-align: right;
  direction: rtl;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-bold;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 16px;
}

h2 {
  font-size: 14px;
}

h3 {
  font-size: 12px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
  font-weight: $font-regular;
}

p {
  font-size: $base-font-size;
  padding-bottom: 0.25rem;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
  @media #{$breakpoint-mobile}{
    font-size: $responsive-font-size;
  }
}

pre {
  overflow: auto hidden;
  direction: ltr !important;
}

strong, b {
  font-weight: $font-bold;
}

::placeholder {
  color: var(--placeholder) !important;
  font-size: 14px !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
