/*@font-face {
  font-family: "IRANSansWeb";
  src: url("../../../assets/fonts/IRANSansWeb.eot") format("eot"),
  url("../../../assets/fonts/IRANSansWeb.woff") format("woff"),
  url("../../../assets/fonts/IRANSansWeb.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: "IRanYekan";
  src: url('../../fonts/IranYekan/eot/iranyekanweblightfanum.eot');
  src: url('../../fonts/IranYekan/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IranYekan/woff/iranyekanweblightfanum.woff') format('woff'),
  url('../../fonts/IranYekan/ttf/iranyekanweblightfanum.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IRanYekan";
  src: url('../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot');
  src: url('../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IranYekan/woff/iranyekanwebregularfanum.woff') format('woff'),
  url('../../fonts/IranYekan/ttf/iranyekanwebregularfanum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IRanYekan";
  src: url('../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot');
  src: url('../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IranYekan/woff/iranyekanwebregularfanum.woff') format('woff'),
  url('../../fonts/IranYekan/ttf/iranyekanwebregularfanum.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "IRanYekan";
  src: url('../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot');
  src: url('../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IranYekan/woff/iranyekanwebboldfanum.woff') format('woff'),
  url('../../fonts/IranYekan/ttf/iranyekanwebboldfanum.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IRanYekan";
  src: url('../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot');
  src: url('../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IranYekan/woff/iranyekanwebboldfanum.woff') format('woff'),
  url('../../fonts/IranYekan/ttf/iranyekanwebboldfanum.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
