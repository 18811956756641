@import "../base/variables";


.btn {
  min-width: 160px;
  height: 48px;
  background: transparent;
  outline: none !important;
  border-radius: $radius !important;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1em;
  line-height: calc(48px - 1rem);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;

  & i {
    font-size: 1.5rem;
    margin-left: 12px;
  }

  @media #{$breakpoint-mobile} {
    min-width: 135px;
    font-size: .8rem;
    padding: .25rem .5rem;

    & i {
      font-size: 1rem;
      margin-left: 6px;
    }
  }
}

.btn-sm {
  min-width: 120px !important;
  height: 40px !important;
}

.btn-wide {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.btn-full {
  width: 100%;
}


.warning-btn{
  background-color: var(--button-warning-bg);
  border-color: var(--button-warning-bg);
  color: var(--black) !important;

  &:active {
    background-color: var(--button-warning-active-bg);
    border-color: var(--button-warning-active-bg);
  }

  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}

.success-btn{
  background-color: var(--button-success-bg);
  border-color: var(--button-success-bg);
  color: var(--white) !important;

  &:active {
    background-color: var(--button-success-active-bg);
    border-color: var(--button-success-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}

.danger-btn{
  background-color: var(--button-danger-bg);
  border-color: var(--button-danger-bg);
  color: var(--white) !important;

  &:active {
    background-color: var(--button-danger-active-bg);
    border-color: var(--button-danger-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}

.secondary-btn{
  background-color: var(--button-secondary-bg);
  border-color: var(--button-secondary-bg);
  color: var(--black) !important;

  &:active {
    background-color: var(--button-secondary-active-bg);
    border-color: var(--button-secondary-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}

.primary-btn{
  background-color: var(--button-primary-bg);
  border-color: var(--button-primary-bg);
  color: var(--white) !important;

  &:active {
    background-color: var(--button-primary-active-bg);
    border-color: var(--button-primary-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}


.light-btn{
  background-color: var(--button-light-bg);
  border-color: var(--button-light-bg);
  color: var(--text) !important;

  &:active {
    background-color: var(--button-light-active-bg);
    border-color: var(--button-light-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}

.dark-btn{
  background-color: var(--button-dark-bg);
  border-color: var(--button-dark-bg);
  color: var(--white) !important;

  &:active {
    background-color: var(--button-dark-active-bg);
    border-color: var(--button-dark-active-bg);
  }


  &:disabled{
    background-color: var(--button-disable-bg);
    border-color: var(--button-disable-bg);
    color: var(--button-disable-color) !important;
  }

}



.link-btn {
  font-weight: $font-light;
  color: var(--primary-link);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--primary-link);
    text-decoration: underline;
  }
}



.btn-loading {
  cursor: progress;
  opacity: 0.5;

  &:hover {
    cursor: progress;
    opacity: 0.5;
  }
}

.return-back-btn {
  font-weight: $font-light;
  color: var(--button-return-color);
  border: none;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    font-family: 'icomoon', serif;
    transform: rotate(180deg);
  }
}

.edit-btn {
  border: 0;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  color: var(--button-edit-icon-color);
  font-size: 0.875rem;

  i {
    font-size: 19px;
    margin-left: 0.25rem;
    font-weight: $font-bold;
  }
}

.filter-btn {
  @extend .btn;
  padding: .5rem 1rem;
  min-width: 118px;
  height: 48px;


  &:after {
    display: none;
  }

  i {
    font-size: 1.3rem;
  }

  @media #{$breakpoint-mobile} {
    min-width: 48px !important;
    margin-bottom: 0.75rem;
    i {
      margin: 0 !important;
      font-size: 1.3rem !important;
    }
    & span {
      display: none;
    }
  }
}

.plan-btn{
  min-width: 120px;
  border-radius: 14px;
  color: var(--black);
  background-color: var(--plan-choose-bg);
  &.disable{
    background-color: var(--plan-choose-disable-bg);
  }
}


