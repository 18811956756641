@import "palet";


:root {

  /* Common */
  --black: #{$black-1};
  --white: #{$black-9};
  --text: #{$black-1};
  --border: #{$black-8};
  --border-2: #{$black-8};
  --shadow: #{$dark-blue-9};
  --background-body: #{$blue-7};
  --background-container: #{$gray-4};
  --background-disable: #{$dark-blue-9};
  --primary-link: #{$blue-1};
  --placeholder: #{$black-6};
  --cart-1-color: #{$black-1};
  --cart-2-color: #{$dark-blue-4};
  --gray-description-background: #{$dark-blue-9};
  --uploaded-title: #{$dark-blue-8};
  --support-file: #{$black-1};


  /* Help */
  --help-close-btn-bg: #{$red-3};
  --help-link-color: #{$green-2};


  /* Code Block */
  --code-block-bg: #{$dark-blue-3};
  --code-block-border-color: #{$black-7};
  --code-block-color: #{$black-9};


  /* FAQ */
  --FAQ-index-bg: #{$yellow-5};
  --FAQ-index-active-bg: #{$yellow-2};
  --FAQ-index-active-color: #{$black-1};
  --FAQ-index-color: #{$black-1};
  --FAQ-dropdown-icon-color: #{$blue-3};


  /* Loading */
  --loading-border-color-1: #{$blue-2};


  /* Header */
  --header-toggle-switch-color: #{$blue-1};
  --header-toggle-switch-bg: #{$blue-5};
  --header-toggle-swich-dark-bg: #{$blue-1};
  --header-items-bg: #{$gray-3};
  --header-items-color: #{$dark-blue-3};


  /* Sidebar */
  --sidebar-menu-color: #{$dark-blue-3};
  --sidebar-menu-close-btn-color: #{$dark-blue-4};
  --sidebar-menu-title-color: #{$black-2};
  --sidebar-menu-sub-color: #{$black-6};
  --sidebar-menu-border-color: #{$blue-3};


  /* Dropdown */
  --dropdown-menu-hover-bg: #{$black-8};
  --dropdown-menu-shadow-color: #{$black-7};

  /* Modal */
  --modal-bg: #{$black-9};
  --modal-backdrop-bg: rgba($dark-blue-1, 1);
  --modal-input-bg: #{$dark-blue-9};


  /* Scroll */
  --scrollbar: #{$black-8};
  --scrollbar-thumb: #{$black-4};


  /* Alert */
  --alert-blue-bg: #{$blue-6};
  --alert-red-bg: #{$red-6};
  --alert-green-bg: #{$green-6};
  --alert-yellow-bg: #{$yellow-6};
  --alert-red-color: #{$red-3};
  --alert-blue-color: #{$blue-3};
  --alert-yellow-color: #{$yellow-1};
  --alert-green-color: #{$green-2};


  /* Notification */
  --notification-bg: #{$yellow-2};


  /* Form */
  --form-text-error-color: #{$red-3};
  --form-input-readonly-color: #{$black-1};
  --form-checkbox-bg: #{$gray-3};
  --form-checkbox-active-bg: #{$blue-3};
  --form-checkbox-checked-bg: #{$black-9};
  --form-radio-bg: #{$black-9};
  --form-radio-color: #{$black-8};
  --form-radio-disabled-color: #{$black-7};
  --form-radio-active-bg: #{$blue-3};
  --form-radio-checked-bg: #{$black-9};
  --form-search-input-icon-color: #{$black-6};
  --form-sms-count-color: #{$black-6};
  --form-switch-active-bg: #{$blue-3};
  --form-input-color: #{$dark-blue-4};
  --form-input-error-color: #{$red-5};
  --form-input-bg: #{$dark-blue-9};
  --form-select-hover-bg: #{$gray-2};
  --form-input-placeholder-color: #{$black-6};
  --form-close-icon-color: #{$black-6};


  /* Campaign */
  --campaign-parameter-link-color: #{$green-2};
  --campaign-link-color: #{$black-6};
  --campaign-blue-title-color: #{$blue-3};
  --campaign-schadule-tag-color: #{$blue-3};
  --campaign-schadule-tag-icon-color: #{$blue-3};
  --campaign-abtest-range-color: #{$black-9};
  --campaign-abtest-range-1-bg: #{$blue-1};
  --campaign-abtest-range-2-bg: #{$blue-2};
  --campaign-abtest-range-3-bg: #{$blue-3};
  --campaign-abtest-range-4-bg: #{$blue-4};
  --campaign-abtest-range-5-bg: #{$blue-5};
  --campaign-abtest-range-5-color: #{$blue-1};
  --campaign-abtest-tag-color: #{$black-8};
  --campaign-abtest-tag-active-color: #{$blue-3};
  --campaign-abtest-tag-active-icon-color: #{$blue-3};
  --campaign-abtest-shorlink-disabled-color: #{$black-6};
  --campaign-title-color: #{$dark-blue-4};
  --campaign-abtest-tag-icon-color: #{$black-1};
  --campaign-abtest-add-category-color: #{$black-6};
  --campaign-abtest-percent-bg: #{$black-8};
  --campaign-abtest-percent-handler-color: #{$dark-blue-4};


  /* BankNumber */
  --banknumber-abtest-shorlink-disabled-color: #{$blue-3};
  --banknumber-color: #{$dark-blue-4};
  --banknumber-count-number-color: #{$black-6};


  /* Plan */
  --plan-free-color: #{$green-2};
  --plan-free-bg: #{$green-5};
  --plan-bronze-color: #{$red-3};
  --plan-bronze-bg: #{$red-5};
  --plan-silver-color: #{$dark-blue-4};
  --plan-silver-bg: #{$dark-blue-9};
  --plan-gold-color: #{$yellow-2};
  --plan-gold-bg: #{$yellow-6};
  --plan-current-bg: #{$gray-3};
  --plan-disabled-color: #{$dark-blue-8};
  --plan-disabled-bg: #{$black-8};
  --plan-active-switch-bg: #{$dark-blue-4};
  --plan-item-disable-bg: #{$black-9};
  --plan-best-offer-bg: #{$blue-1};
  --plan-best-offer-disable-bg: #{$black-4};
  --plan-best-offer-color: #{$black-9};
  --plan-title-bg: #{$yellow-4};
  --plan-title-color: #{$black-1};
  --plan-title-disable-bg: #{$black-9};
  --plan-old-price-color: #{$black-6};;
  --plan-discount-percentage-bg: #{$blue-1};
  --plan-discount-percentage-disable-bg: #{$black-4};
  --plan-discount-percentage-color: #{$black-9};
  --plan-more-info-bg: #{$gray-3};
  --plan-more-info-disable-bg: #{$black-8};
  --plan-more-info-color: #{$black-1};
  --plan-choose-bg: #{$yellow-2};
  --plan-choose-disable-bg: #{$black-7};
  --plan-prev-next-color: #{$yellow-2};
  --plan-table-header-bg: #{$yellow-2};
  --plan-table-header-disable-bg: #{$gray-4};
  --plan-table-row-bg: #{$yellow-7};
  --plan-table-row-disable-bg: #{$black-8};
  --plan-table-row-odd-disable-bg: #{$gray-4};
  --plan-table-row-even-disable-bg: #{$gray-4};
  --plan-table-column-hover-bg: #{$yellow-6};
  --plan-table-price-row-bg: #{$yellow-5};
  --plan-table-border-color: #{$black-8};
  --plan-table-price-row-disable-bg: #{$gray-3};
  --plan-card-border-color: #{$yellow-2};
  --plan-card-border-disable-color: #{$black-7};

  /* Ai */
  --ai-bg-color: #{$blue-3};
  --ai-edit-color: #{$black-9};
  --ai-undo-color: #{$black-5};


  /* Toast */
  --toast-info: #{$blue-3};
  --toast-warning: #{$yellow-2};
  --toast-error: #{$red-3};
  --toast-success: #{$green-2};
  --toast-icon-info: #{$blue-6};
  --toast-icon-warning: #{$yellow-5};
  --toast-icon-error: #{$red-5};
  --toast-icon-success: #{$green-6};
  --toast-shadow: #{$gray-2};


  /* Progress */
  --progress-bg: #{$yellow-4};
  --progress-success-bg: #{$green-2};


  /* Button */
  --button-warning-bg: #{$yellow-2};
  --button-warning-active-bg: #{$yellow-3};
  --button-success-bg: #{$green-2};
  --button-success-active-bg: #{$green-3};
  --button-danger-bg: #{$red-2};
  --button-danger-active-bg: #{$red-3};
  --button-secondary-bg: #{$dark-blue-8};
  --button-secondary-active-bg: #{$dark-blue-9};
  --button-primary-bg: #{$blue-1};
  --button-primary-active-bg: #{$blue-2};
  --button-light-bg: #{$gray-3};
  --button-light-active-bg: #{$dark-blue-9};
  --button-dark-bg: #{$dark-blue-4};
  --button-dark-active-bg: #{$dark-blue-5};
  --button-return-color: #{$black-6};
  --button-edit-icon-color: #{$blue-2};
  --button-disable-bg: #{$black-8};
  --button-disable-color: #{$black-6};


  /* Step */
  --step-disabled-bg: #{$dark-blue-9};
  --step-border-disabled-color: #{$dark-blue-9};
  --step-active-bg: #{$blue-3};
  --step-active-border-color: #{$blue-5};
  --step-title-color: #{$dark-blue-4};


  /* Table */
  --table-header-bg: #{$blue-6};
  --table-pagination-recode-text: #{$black-6};
  --table-pagination-bg: #{$gray-3};
  --table-pagination-active-bg: #{$blue-3};
  --table-pagination-active-text: #{$black-9};
  --table-th-text: #{$black-1};
  --table-td-text: #{$black-1};
  --table-action-text: #{$black-1};
  --table-action-bg: #{$dark-blue-9};
  --table-action-bg-hover: #{$gray-2};
  --table-action-bg-dots: #{$black-6};
  --table-filter-bg: #{$gray-3};
  --table-filter-input-bg: #{$black-9};
  --table-filter-drop-down-hover: #{$dark-blue-9};
  --table-filter-button-active-text: #{$dark-blue-4};
  --table-filter-button-bg: #{$dark-blue-8};
  --table-filter-button-active-bg: #{$dark-blue-9};
  --table-filter-applied: #{$blue-3};
  --table-filter-applied-icon: #{$blue-3};
  --table-drop-down-icon: #{$blue-3};
  --table-mobile-value-color: #{$black-6};


  /* Tab */
  --tab-border-color: #{$black-8};
  --tab-border-active-color: #{$black-1};


  --tab-color: #{$black-1};
  --tab-bg: #{$black-8};
  --tab-active-bg: #{$dark-blue-1};
  --tab-active-color: #{$dark-blue-1};
  --tab-badge-bg: #{$yellow-2};
  --tab-badge-color: #{$black-1};
  --tab-active-badge-bg: #{$blue-2};
  --tab-active-badge-color: #{$black-9};


  /* Ticket */
  --ticket-admin-bg: #{$dark-blue-7};
  --ticket-client-bg: #{$blue-3};
  --ticket-owner-info-color: #{$black-6};


  /* Payment */
  --payment-price-color: #{$green-2};
  --payment-add-fund-discount-badge-bg: #{$blue-3};
  --payment-add-fund-per-price-color: #{$yellow-2};


  /* Developers */
  --help-menu-active-bg: #{$blue-1};
  --help-submenu-active-color: #{$blue-3};
  --help-submenu-bg: #{$blue-6};
  --help-api-color: #{$dark-blue-4};
  --help-list-bg: #{$black-8};


  /* Dashboard */
  --dashboard-red-bg: #{$red-5};
  --dashboard-green-bg: #{$green-4};
  --dashboard-orange-bg: #{$yellow-4};
  --dashboard-blue-bg: #{$blue-4};
  --dashboard-red-color: #{$red-4};
  --dashboard-green-color: #{$green-2};
  --dashboard-orange-color: #{$yellow-2};
  --dashboard-blue-color: #{$blue-3};


  /* Footer */
  --footer-bg: #{$gray-3};
  --footer-color: #{$dark-blue-3};


  /* Skeleton */
  --skeleton-color-1: #{$black-8};
  --skeleton-color-2: #{$gray-4};
  --skeleton-color-3: #{$dark-blue-9};
  --skeleton-color-4: #{$dark-blue-9};


  /* Logo */
  --logo-bg: #{$yellow-2};
  --logo-fill-color: #{$black-1};
  --logo-shape-color: #{$black-1};


  /* Payamak sefid */
  --ps-dark-btn-bg: #{$dark-blue-3};
  --ps-dark-active-btn-bg: #{$dark-blue-5};
  --ps-form-input-readonly-color: #{$black-6};
  --ps-title-bg: #{$blue-3};
}
