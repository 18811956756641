@import "../base/variables";


.alert {
  border-radius: $radius;
  padding: .8rem;
  display: flex;
  text-align: right;
  align-items: flex-start;
  margin: 0;

  &-warning {
    color: var(--alert-yellow-color) !important;
    background-color: var(--alert-yellow-bg) !important;
    border-color: var(--alert-yellow-bg) !important;
  }

  &-success {
    color: var(--alert-green-color) !important;
    background-color: var(--alert-green-bg) !important;
    border-color: var(--alert-green-bg) !important;
  }

  &-info {
    color: var(--alert-blue-color) !important;
    background-color: var(--alert-blue-bg) !important;
    border-color: var(--alert-blue-bg) !important;
  }

  &-danger {
    color: var(--alert-red-color) !important;
    background-color: var(--alert-red-bg) !important;
    border-color: var(--alert-red-bg) !important;
  }

  i {
    font-size: 1.5rem;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
  }

  & p {
    padding: 4px !important;
    font-weight: 400;
    line-height: 1.5;
  }

  &-slim {
    padding: 0.5rem 1rem;

    i {
      font-size: 1.25rem;
    }

    p {
      font-weight: $font-regular;
      font-size: 0.875rem;
    }
  }
}
