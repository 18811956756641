@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin limited-text($line:9) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin box-shadow-general() {
  -webkit-box-shadow: 0 16px 45px var(--shadow);
  -moz-box-shadow: 0 16px 45px var(--shadow);
  box-shadow: 0 16px 45px var(--shadow);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

//===============================================
// Color Placeholder
//===============================================
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}






