@import "../base/variables";

.params-ltr {
  .params {
    &__list {
      justify-content: space-between;

      &__label, &__value {
        word-break: break-word;
        max-width: inherit !important;
        flex: inherit !important;
      }

      &__value {
        max-width: 60% !important;
        white-space: pre-line;
        text-align: left;
      }
    }
  }
}

.params {
  list-style: none;
  padding: 0;
  border-bottom: none !important;

  &__list {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid var(--border);
    margin: 0;

    &:last-child {
      border-bottom: none;
    }

    &__label {
      flex: 0 0 24%;
      max-width: 24%;
    }

    &__value {
      flex: 0 0 76%;
      max-width: 76%;
    }

    &__label, &__value {
      padding: 1rem 0 1rem 0.5rem;
      font-weight: 400;

      //&--overflow {
      //  max-height: 48px !important;
      //  overflow: auto !important;
      //}
    }
  }

  &__ltr {
    @extend .params-ltr;
  }

}

//tablet
@media #{$breakpoint-tablet}{
  .params {
    &__list {
      justify-content: space-between;

      &__label {
        flex: 0 0 40% !important;
        max-width: 40% !important;
      }

      &__value {
        flex: 0 0 60% !important;
        max-width: 60% !important;
      }
    }
  }
}

//mobile
@media #{$breakpoint-mobile}{
  .params {
    &__list {
      /*  &__label {
          -webkit-box-flex: 0;
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }

        &__value {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100% !important;
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }*/
      justify-content: space-between;

      &__label, &__value {
        word-break: break-word;
        max-width: inherit;
        flex: inherit;
      }

      &__value {
        max-width: 60% !important;
        white-space: pre-line;
        text-align: left;
      }
    }
  }
}

