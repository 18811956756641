@import "../base/variables";


.api-help-wrapper {
  > p {
    color: var(--help-api-color);
    padding: 0;
    font-weight: $font-regular;
    line-height: 28px;
  }

  > h5 {
    color: var(--help-api-color);
  }

  .list {
    &-wrapper {

    }

    &-item {
      direction: ltr;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      @media #{$breakpoint-desktop} {
        flex-direction: column;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }

    &-key {
      background-color: var(--help-list-bg);
      color: var(--help-api-color);
      font-weight: $font-regular;
      border-radius: $radius;
      min-height: 2.5rem;
      padding: 0.5rem 1rem;
      width: 33.3333%;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media #{$breakpoint-desktop} {
        width: 100%;
        margin-bottom: 1rem;
      }

      button {
        border: 0;
        font-size: 18px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text);
      }
    }

    &-value {
      &-tabs{
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        & span{
          padding: 2px 12px;
          cursor: pointer;
          color: var(--help-api-color);
          &.active{
            background: var(--background-container);
            border-radius: $radius;
          }
        }
        & .copy-btn {
          border: 0;
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--text);
        }
      }
      background-color: var(--help-list-bg);
      color: var(--help-api-color);
      font-weight: $font-regular;
      border-radius: $radius;
      min-height: 2.5rem;
      padding: 0.5rem 1rem 0;
      width: calc(66.6666% - 1rem);
      @media #{$breakpoint-desktop} {
        width: 100%;
      }

      input {
        height: 100%;
        font-weight: 400;
        padding: 0;
        background: transparent;
        border: 0;
        border-radius: 0;
        color: var(--text);
        cursor: default;
      }

      > pre {
        padding-bottom: 0.5rem;
        color: var(--text);
        font-size: 1rem;
        margin: 0;
      }

      > span {
        display: block;
        width: 100%;
        word-break: break-all;
      }
    }
  }
}

table.custom-table {
  width: 100%;
  color: var(--text);
  text-align: center;
  font-size: 0.875rem;
  @media #{$breakpoint-desktop} {
    display: block;
  }

  thead {
    tr {
      height: 40px;
      background: var(--table-header-bg);

      td {
        &:first-child {
          border-radius: 0 .9rem .9rem 0;
        }

        &:last-child {
          border-radius: .9rem 0 0 .9rem;
        }

      }
    }

    @media #{$breakpoint-desktop} {
      display: none;
    }
  }

  tbody {
    @media #{$breakpoint-desktop} {
      display: block;
    }

    tr {
      vertical-align: middle;
      height: 55px;
      border-bottom: 1px solid var(--border);
      font-weight: $font-light;
      @media #{$breakpoint-desktop} {
        display: block;
        height: auto;
      }

      td {
        min-width: 60px;
        @media #{$breakpoint-desktop} {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.5rem 0;
          font-size: 0.875rem;
          &:before {
            content: attr(data-header);
          }
        }
      }
    }
  }
}
