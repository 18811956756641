@import "../base/variables";


.modal {
  background: var(--modal-backdrop-bg);

  input:not([type="checkbox"]), textarea, .ng-select .ng-select-container {
    background-color: var(--modal-input-bg) !important;
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 385px !important;
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--modal-bg);
}

.modal-header {
  display: block;
  border: none;
  padding: 0;

  img {
    height: 100%;
    padding-bottom: 1rem;
  }
}

.modal-body {
  padding: 1px;
}

.modal-footer {
  display: block;
  border: none;
  padding: 1rem 0 0 0;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.modal-table {
  width: 100%;
  outline: 0;
  margin-bottom: 0;

  &__item {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--border-2);

    &:last-child {
      border-bottom: none;
    }

    &--header,
    &--value {
      padding: 1rem;
      text-align: center;
      color: var(--text);
      //font-size: 16px;
    }

    &--header {
      text-align: right;
      //flex-basis: 250px;
      flex-shrink: 0;
      max-width: 60%;
    }

    &--value {
      word-break: break-word;
      white-space: pre-line;
      max-height: 110px;
      overflow-y: auto;
      text-align: left;
      flex-grow: 1;
    }
  }

  @media #{$breakpoint-mobile}{
    &__item {
      &--header,
      &--value {
        font-size: 14px;
      }
    }
  }
}

.transparent-modal {
  .modal-content {
    padding: 0;
    background: transparent;
    border: none;
  }
}
