@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icons/icomoon.eot?ybh4q6');
  src: url('../../fonts/icons/icomoon.eot?ybh4q6#iefix') format('embedded-opentype'),
  url('../../fonts/icons/icomoon.ttf?ybh4q6') format('truetype'),
  url('../../fonts/icons/icomoon.woff?ybh4q6') format('woff'),
  url('../../fonts/icons/icomoon.svg?ybh4q6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left2:before {
  content: "\2b60";
}

.icon-dots-vertical:before {
  content: "\e931";
}

.icon-plans:before {
  content: "\e932";
}

.icon-eye-slash:before {
  content: "\e930";
}

.icon-add-message:before {
  content: "\e900";
}

.icon-add-user:before {
  content: "\e901";
}

.icon-arrow:before {
  content: "\e902";
}

.icon-calender:before {
  content: "\e903";
}

.icon-folder:before{
  content: "\1f5c1";

}

.icon-cancel:before {
  content: "\e904";
}

.icon-card:before {
  content: "\e905";
}

.icon-chart:before {
  content: "\e906";
}

.icon-chart-line:before {
  content: "\e907";
}

.icon-check:before {
  content: "\e908";
}

.icon-cross:before {
  content: "\2715";
}

.icon-circle-add:before {
  content: "\e909";
}

.icon-circle-arrow:before {
  content: "\e90a";
}

.icon-circle-info:before {
  content: "\e90b";
}

.icon-circle-minus:before {
  content: "\e90c";
}

.icon-code:before {
  content: "\e90d";
}

.icon-commenr:before {
  content: "\e90e";
}

.icon-contact:before {
  content: "\e90f";
}

.icon-contact-us:before {
  content: "\e910";
}

.icon-copy:before {
  content: "\e911";
}

.icon-dark:before {
  content: "\e912";
}

.icon-dashboard:before {
  content: "\e913";
}

.icon-download:before {
  content: "\e914";
}

.icon-download-file:before {
  content: "\e915";
}

.icon-edit:before {
  content: "\e916";
}

.icon-features:before {
  content: "\e917";
}

.icon-filter:before {
  content: "\e918";
}

.icon-form:before {
  content: "\e919";
}

.icon-grid:before {
  content: "\e91a";
}

.icon-help:before {
  content: "\e91b";
}

.icon-key:before {
  content: "\e91c";
}

.icon-law:before {
  content: "\e91d";
}

.icon-light:before {
  content: "\e91e";
}

.icon-list:before {
  content: "\e91f";
}

.icon-mail:before {
  content: "\e920";
}

.icon-menu:before {
  content: "\e921";
}

.icon-minus:before {
  content: "\e922";
}

.icon-notification:before {
  content: "\e923";
}

.icon-phone:before {
  content: "\e924";
}

.icon-plus:before {
  content: "\e925";
}

.icon-plus-mail:before {
  content: "\e926";
}

.icon-refresh:before {
  content: "\e927";
}

.icon-report:before {
  content: "\e928";
}

.icon-search:before {
  content: "\e929";
}

.icon-setting:before {
  content: "\e92a";
}

.icon-trash:before {
  content: "\e92b";
}

.icon-upload:before {
  content: "\e92c";
}

.icon-user:before {
  content: "\e92d";
}

.icon-view:before {
  content: "\e92e";
}

.icon-wallet:before {
  content: "\e92f";
}

.icon-activation:before {
  content: "\e933";
}
