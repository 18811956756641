@import "../base/variables";
@import "../base/mixins";

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.right {
  float: right;
}

.left {
  float: left;
}

.pointer {
  cursor: pointer;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.cursor-help{
  cursor: help;
}

.push-left {
  margin-right: auto;
}

.push-right {
  margin-left: auto;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.fa-1-5x {
  font-size: 1.5rem;
}

.font-10{
  font-size: 10px !important;
}

.font-12{
  font-size: 12px !important;
}

.font-14{
  font-size: 14px;
}

.font-16{
  font-size: 16px;
}

.font-22{
  font-size: 22px;
}

.font-28{
  font-size: 28px !important;
}

.font-30{
  font-size: 30px !important;
}

.font-40{
  font-size: 40px !important;
}

.mbsc-fr-w,
.mbsc-cal-picker {
  font-family: $base-font-family, sans-serif !important;
  background: var(--background-container) !important;
  color: var(--text) !important;
}

.mt-6 {
  margin-top: 2rem;
}

.mb-6 {
  margin-bottom: 2rem;
}

.my-6 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.radius-10{
  border-radius: 10px;
}

@media (min-width: 768px) {
  .mb-md-6, .my-md-6 {
    margin-bottom: 2rem !important;
  }
  .mt-md-6, .my-md-6 {
    margin-top: 2rem !important;
  }
}


hr {
  margin: 1rem;
  border-top: 1px solid var(--border);
}

/*#region scrollbar*/
* {
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar);
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  background-color: var(--scrollbar);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--scrollbar);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  background-color: var(--scrollbar-thumb);
  border-radius: 0.5rem;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: var(--placeholder) !important;
}

.c-wrapper {
  display: flex;
  flex-direction: column;
}

.c-header {
  padding: 0 2rem;
  display: flex;
  @media #{$breakpoint-mobile} {
    padding: 0 1rem;
    margin-right: -2px;
  }
}

.c-main {
  margin: 2rem 2rem 0;
  flex-grow: 1;

  @media only screen and (max-width: 1320px) {
    margin: 1rem 1rem 0;
  }
}

.box-container {
  padding: 1.5rem;
  border-radius: $radius;
  @include box-shadow-general();
  background-color: var(--background-container);
}

.show-details {
  cursor: pointer;
  text-align: left;
  margin-top: 0.5rem;
  padding-bottom: 0;
  color: var(--primary-link);
  @media #{$breakpoint-mobile}{
    font-size: $base-font-size;
    span {
      font-size: inherit;
    }
  }
}

/*#region tab*/
.tab-div {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1.5rem;
  width: fit-content;
  @media #{$breakpoint-mobile}{
    width: fit-content;
    //white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background: var(--tab-bg);
    //@media #{$breakpoint-mobile}{
    //  background: var(--background-body);
    //}
  }

  .title-tab {
    a, > span {
      padding: 0 0 1.5rem 0;
      margin-left: 1.5rem;
      color: var(--tab-color);
      cursor: pointer;
      display: block;

      &.active {
        color: var(--tab-color);
        font-weight: 700;
        position: relative;

        &:before {
          content: ' ';
          position: absolute;
          width: 100%;
          border-radius: 0.5rem;
          height: 3px;
          background: var(--tab-active-bg);
          bottom: -1px;
          right: 0;
        }
      }
    }

    &:last-child {
      a, > span {
        margin-left: 0;
      }
    }
  }
}

.descriptor {
  @media screen and (max-width: 768px) {
    & button {
      margin-top: 1rem !important;
    }
  }
  @media #{$breakpoint-mobile}{
    .d-flex {
      flex-wrap: wrap;
      width: 100%;
    }
    & button {
      margin: 1rem 0 0 0 !important;
      min-width: 100%;
    }
  }
}

/*#select box */
.row-box {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem  -0.75rem 0;
}

.main-box {
  background-color: var(--background-container);
  border-radius: 1rem;
  height: 220px;
  width: 220px;
  cursor: pointer;
  transition: all 400ms ease;
  padding: 1rem 0.8rem 0 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin: 0 1rem 1rem 1rem;
  margin: 0 0.75rem 1.5rem 0.75rem;
  @include box-shadow-general();

  @media screen and (min-width: 1501px) and (max-width: 1600px) {
    width: calc(100% / 5 - 1.5rem);
  }

  @media screen and (min-width: 1301px) and (max-width: 1500px) {
    width: calc(100% / 4 - 1.5rem);
  }

  @media screen and (min-width: 768px) and (max-width: 1300px) {
    width: calc(100% / 3 - 1.5rem);
  }

  @media screen and (min-width: 466px) and (max-width: 767px) {
    width: calc(100% / 2 - 1.5rem);
  }
  @media screen and (max-width: 465px) {
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 150px;
  }

  p:nth-child(1) {
    color: var(--cart-1-color);
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: .9rem;
    padding: 0;
    text-align: center;
  }

  p:nth-child(2) {
    color: var(--cart-2-color);
    line-height: 20px;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    height: 44px;
  }

  &:hover {
    transform: translateY(-3px);
  }

  > div.disable{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 1rem;
    background-color: var(--background-disable);
    opacity: .5;
  }
}

// it's a style for description at the confirm message component
.info-box {
  background-color: var(--gray-description-background);
  padding: 1rem;
  border-radius: $radius;
  min-height: 60px;

  p {
    white-space: pre-line;
    line-height: 1.5;
    padding-bottom: 0;
  }

  @media #{$breakpoint-mobile}{
    p {
      font-size: $base-font-size;
    }
  }
}

//
.selected-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;

  span {
    font-size: 0.875rem;
    padding-bottom: 0;

    @media #{$breakpoint-mobile} {
      font-size: 0.75rem;
    }
  }
}

// Animation
@keyframes move-forward {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}

.arrow-left-move {
  animation: 1s ease-in infinite move-forward;
}


// we use this style in the send module for title
.send-confirm-title,
.sender-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  @media #{$breakpoint-laptop} {
    justify-content: flex-start;
  }
}

.without-data-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    min-width: fit-content;
    margin-right: 0.5rem;
  }
}

