@import '../base/variables';


.form-group{
  position: relative;
  margin-bottom: 1.2rem;
}

input, select, textarea {
  background: var(--form-input-bg);
  border: 2px solid var(--form-input-bg);
  width: 100%;
  border-radius: $radius;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-weight: $font-light;
  position: relative;
  z-index: 5;
  color: var(--form-input-color);

  &.white-control {
    background-color: var(--table-filter-input-bg);
    border-color: var(--table-filter-input-bg);
  }

  &::-webkit-input-placeholder {
    color: var(--form-input-placeholder-color);
  }

  &:-ms-input-placeholder {
    color: var(--form-input-placeholder-color);
  }

  &::placeholder {
    color: var(--form-input-placeholder-color);
  }

  &:read-only:not(.mbsc-comp) {
    color: var(--form-input-readonly-color);
    cursor: no-drop;
  }

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    box-shadow: 0 0 100px var(--form-input-bg) inset;
    background: transparent;

    &:hover,
    &:focus {
      box-shadow: 0 0 100px var(--form-input-bg) inset;
      background: transparent;
    }
  }
}


label {
  display: block;
  font-size: 1rem;
  margin: 0.5rem 0;
  text-align: right;
}

textarea {
  resize: none;
  min-height: 10rem;
}

.input-error {
  border-color: var(--form-input-error-color);
}

.ng-select {
  min-width: 140px;

  .ng-select-container {
    background: var(--form-input-bg) !important;
    width: 100% !important;
    border: 0;
    border-color: unset !important;
    border-radius: $radius !important;
    height: 2.5rem !important;
    padding: 0.5rem 1rem;
    box-shadow: none !important;
    color: var(--form-input-color);

    .ng-value-container {
      padding: 0 !important;
    }

    input {
      height: initial;
    }

    .ng-value-label {
      font-weight: $font-light;
    }
  }

  .ng-dropdown-panel {
    &.ng-select-bottom {
      top: 3rem;
      border-radius: $radius;
      border: 0;
      background: var(--form-input-bg);
      padding: 1rem 0;
      box-shadow: 2px 15px 24px 0 var(--dropdown-menu-shadow-color);

    }

    &.ng-select-top {
      bottom: 3rem;
      border-radius: $radius;
      border: 0;
      background: var(--form-input-bg);
      padding: 1rem 0;
      box-shadow: 2px 15px 24px 0 var(--dropdown-menu-shadow-color);
    }

    .ng-dropdown-panel-items {
      .ng-option {
        background-color: transparent;
        border-radius: $radius !important;
        margin-bottom: 0.25rem;
        color: var(--text);

        &:hover {
          background-color: var(--form-select-hover-bg);
        }

        &.ng-option-disabled {
          color: var(--form-input-placeholder-color);
        }

        .ng-option-label {
          font-weight: $font-light;
        }

        &.ng-option-selected {
          background-color: var(--form-select-hover-bg);
          color: var(--text);

          span {
            font-weight: $font-light !important;
          }
        }
      }
    }
  }

  &.white-control {
    .ng-select-container {
      background: var(--table-filter-input-bg) !important;
    }

    .ng-dropdown-panel {
      &.ng-select-bottom {
        background: var(--table-filter-input-bg) !important;
      }

      .ng-dropdown-panel-items {
        .ng-option {
          &.ng-option-selected,
          &:hover {
            background-color: var(--table-filter-drop-down-hover);
          }
        }
      }
    }
  }
}


.input-prepend {
  display: flex;
  align-items: center;

  &-box {
    flex: 3rem;
    margin-right: 0.5rem;
    background: var(--form-input-bg);
    height: 2.5rem;
    border-radius: $radius;
    line-height: 2.5rem;
    text-align: center;
    padding: 0 0.5rem;

    span {
      font-weight: $font-light;
      font-size: 1rem;
    }
  }
}

.search-wrapper {
  margin-bottom: .5rem;
  position: relative;

  &__input {
    padding-right: 50px;
    width: 100%;
  }

  &__icon {
    position: absolute;
    right: 15px;
    font-size: 1.5rem;
    color: var(--form-search-input-icon-color);
    top: 50%;
    transform: translateY(-50%);
    z-index: 6;
  }
}

.input-container {
  position: relative;

  .hint-password {
    z-index: 6;
    position: absolute;
    top: 9px;
    left: 18px;
    cursor: pointer;

    & i {
      font-size: 1.5rem;
      color: var(--form-close-icon-color);
    }
  }
}

//.radio-btn ::ng-deep{
//  & > div{
//    &:first-child{
//      margin-bottom: 1rem;
//    }
//  }
//}
