@charset "UTF-8";
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'highlight.js/styles/github-dark-dimmed.css';
@tailwind base;
@tailwind utilities;
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .filter-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .filter-btn {
    transition: none;
  }
}
.btn:hover, .filter-btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .filter-btn:focus, .btn.focus, .focus.filter-btn {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .disabled.filter-btn, .btn:disabled, .filter-btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .filter-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, a.disabled.filter-btn,
fieldset:disabled a.btn,
fieldset:disabled a.filter-btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .filter-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .filter-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .filter-btn,
.btn-group-vertical > .btn,
.btn-group-vertical > .filter-btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .btn-group > .filter-btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .filter-btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .filter-btn:focus, .btn-group > .btn:active, .btn-group > .filter-btn:active, .btn-group > .btn.active, .btn-group > .active.filter-btn,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .filter-btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .filter-btn:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.filter-btn {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .filter-btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .filter-btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .filter-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .btn-group > .filter-btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .filter-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .filter-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .filter-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .filter-btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .filter-btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .filter-btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .filter-btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .filter-btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .filter-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .filter-btn,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .filter-btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .filter-btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .filter-btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .input-group-prepend .filter-btn,
.input-group-append .btn,
.input-group-append .filter-btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .input-group-prepend .filter-btn:focus,
.input-group-append .btn:focus,
.input-group-append .filter-btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .input-group-prepend .filter-btn + .btn, .input-group-prepend .btn + .filter-btn, .input-group-prepend .filter-btn + .filter-btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .filter-btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .filter-btn,
.input-group-append .btn + .btn,
.input-group-append .filter-btn + .btn,
.input-group-append .btn + .filter-btn,
.input-group-append .filter-btn + .filter-btn,
.input-group-append .btn + .input-group-text,
.input-group-append .filter-btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .filter-btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .filter-btn,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .filter-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .filter-btn,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .filter-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .filter-btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .filter-btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .filter-btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .filter-btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .filter-btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .filter-btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .filter-btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .filter-btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn):not(.filter-btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*@font-face {
  font-family: "IRANSansWeb";
  src: url("../../../assets/fonts/IRANSansWeb.eot") format("eot"),
  url("../../../assets/fonts/IRANSansWeb.woff") format("woff"),
  url("../../../assets/fonts/IRANSansWeb.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}*/
@font-face {
  font-family: "IRanYekan";
  src: url("../../fonts/IranYekan/eot/iranyekanweblightfanum.eot");
  src: url("../../fonts/IranYekan/eot/iranyekanweblightfanum.eot?#iefix") format("embedded-opentype"), url("../../fonts/IranYekan/woff/iranyekanweblightfanum.woff") format("woff"), url("../../fonts/IranYekan/ttf/iranyekanweblightfanum.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "IRanYekan";
  src: url("../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot");
  src: url("../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot?#iefix") format("embedded-opentype"), url("../../fonts/IranYekan/woff/iranyekanwebregularfanum.woff") format("woff"), url("../../fonts/IranYekan/ttf/iranyekanwebregularfanum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IRanYekan";
  src: url("../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot");
  src: url("../../fonts/IranYekan/eot/iranyekanwebregularfanum.eot?#iefix") format("embedded-opentype"), url("../../fonts/IranYekan/woff/iranyekanwebregularfanum.woff") format("woff"), url("../../fonts/IranYekan/ttf/iranyekanwebregularfanum.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "IRanYekan";
  src: url("../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot");
  src: url("../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot?#iefix") format("embedded-opentype"), url("../../fonts/IranYekan/woff/iranyekanwebboldfanum.woff") format("woff"), url("../../fonts/IranYekan/ttf/iranyekanwebboldfanum.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "IRanYekan";
  src: url("../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot");
  src: url("../../fonts/IranYekan/eot/iranyekanwebboldfanum.eot?#iefix") format("embedded-opentype"), url("../../fonts/IranYekan/woff/iranyekanwebboldfanum.woff") format("woff"), url("../../fonts/IranYekan/ttf/iranyekanwebboldfanum.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
:root {
  /* Common */
  --black: #000000;
  --white: #FFFFFF;
  --text: #000000;
  --border: #EAEAEA;
  --border-2: #EAEAEA;
  --shadow: #D9DFED;
  --background-body: #F1F5FF;
  --background-container: #F7FAFB;
  --background-disable: #D9DFED;
  --primary-link: #0041FF;
  --placeholder: #969696;
  --cart-1-color: #000000;
  --cart-2-color: #374B76;
  --gray-description-background: #D9DFED;
  --uploaded-title: #B2BFDB;
  --support-file: #000000;
  /* Help */
  --help-close-btn-bg: #F02E00;
  --help-link-color: #19C964;
  /* Code Block */
  --code-block-bg: #25324F;
  --code-block-border-color: #C0C0C0;
  --code-block-color: #FFFFFF;
  /* FAQ */
  --FAQ-index-bg: #FFE18B;
  --FAQ-index-active-bg: #FFBE00;
  --FAQ-index-active-color: #000000;
  --FAQ-index-color: #000000;
  --FAQ-dropdown-icon-color: #5D86FF;
  /* Loading */
  --loading-border-color-1: #2E64FF;
  /* Header */
  --header-toggle-switch-color: #0041FF;
  --header-toggle-switch-bg: #B9CBFF;
  --header-toggle-swich-dark-bg: #0041FF;
  --header-items-bg: #DFE2E8;
  --header-items-color: #25324F;
  /* Sidebar */
  --sidebar-menu-color: #25324F;
  --sidebar-menu-close-btn-color: #374B76;
  --sidebar-menu-title-color: #181818;
  --sidebar-menu-sub-color: #969696;
  --sidebar-menu-border-color: #5D86FF;
  /* Dropdown */
  --dropdown-menu-hover-bg: #EAEAEA;
  --dropdown-menu-shadow-color: #C0C0C0;
  /* Modal */
  --modal-bg: #FFFFFF;
  --modal-backdrop-bg: rgba($dark-blue-1, 1);
  --modal-input-bg: #D9DFED;
  /* Scroll */
  --scrollbar: #EAEAEA;
  --scrollbar-thumb: #424242;
  /* Alert */
  --alert-blue-bg: #E8EEFF;
  --alert-red-bg: #FFE0D8;
  --alert-green-bg: #DFFBEB;
  --alert-yellow-bg: #FFEDB9;
  --alert-red-color: #F02E00;
  --alert-blue-color: #5D86FF;
  --alert-yellow-color: #856404;
  --alert-green-color: #19C964;
  /* Notification */
  --notification-bg: #FFBE00;
  /* Form */
  --form-text-error-color: #F02E00;
  --form-input-readonly-color: #000000;
  --form-checkbox-bg: #DFE2E8;
  --form-checkbox-active-bg: #5D86FF;
  --form-checkbox-checked-bg: #FFFFFF;
  --form-radio-bg: #FFFFFF;
  --form-radio-color: #EAEAEA;
  --form-radio-disabled-color: #C0C0C0;
  --form-radio-active-bg: #5D86FF;
  --form-radio-checked-bg: #FFFFFF;
  --form-search-input-icon-color: #969696;
  --form-sms-count-color: #969696;
  --form-switch-active-bg: #5D86FF;
  --form-input-color: #374B76;
  --form-input-error-color: #FFC1B2;
  --form-input-bg: #D9DFED;
  --form-select-hover-bg: #BFC4D2;
  --form-input-placeholder-color: #969696;
  --form-close-icon-color: #969696;
  /* Campaign */
  --campaign-parameter-link-color: #19C964;
  --campaign-link-color: #969696;
  --campaign-blue-title-color: #5D86FF;
  --campaign-schadule-tag-color: #5D86FF;
  --campaign-schadule-tag-icon-color: #5D86FF;
  --campaign-abtest-range-color: #FFFFFF;
  --campaign-abtest-range-1-bg: #0041FF;
  --campaign-abtest-range-2-bg: #2E64FF;
  --campaign-abtest-range-3-bg: #5D86FF;
  --campaign-abtest-range-4-bg: #8BA9FF;
  --campaign-abtest-range-5-bg: #B9CBFF;
  --campaign-abtest-range-5-color: #0041FF;
  --campaign-abtest-tag-color: #EAEAEA;
  --campaign-abtest-tag-active-color: #5D86FF;
  --campaign-abtest-tag-active-icon-color: #5D86FF;
  --campaign-abtest-shorlink-disabled-color: #969696;
  --campaign-title-color: #374B76;
  --campaign-abtest-tag-icon-color: #000000;
  --campaign-abtest-add-category-color: #969696;
  --campaign-abtest-percent-bg: #EAEAEA;
  --campaign-abtest-percent-handler-color: #374B76;
  /* BankNumber */
  --banknumber-abtest-shorlink-disabled-color: #5D86FF;
  --banknumber-color: #374B76;
  --banknumber-count-number-color: #969696;
  /* Plan */
  --plan-free-color: #19C964;
  --plan-free-bg: #C0F7D7;
  --plan-bronze-color: #F02E00;
  --plan-bronze-bg: #FFC1B2;
  --plan-silver-color: #374B76;
  --plan-silver-bg: #D9DFED;
  --plan-gold-color: #FFBE00;
  --plan-gold-bg: #FFEDB9;
  --plan-current-bg: #DFE2E8;
  --plan-disabled-color: #B2BFDB;
  --plan-disabled-bg: #EAEAEA;
  --plan-active-switch-bg: #374B76;
  --plan-item-disable-bg: #FFFFFF;
  --plan-best-offer-bg: #0041FF;
  --plan-best-offer-disable-bg: #424242;
  --plan-best-offer-color: #FFFFFF;
  --plan-title-bg: #FFD65D;
  --plan-title-color: #000000;
  --plan-title-disable-bg: #FFFFFF;
  --plan-old-price-color: #969696;
  --plan-discount-percentage-bg: #0041FF;
  --plan-discount-percentage-disable-bg: #424242;
  --plan-discount-percentage-color: #FFFFFF;
  --plan-more-info-bg: #DFE2E8;
  --plan-more-info-disable-bg: #EAEAEA;
  --plan-more-info-color: #000000;
  --plan-choose-bg: #FFBE00;
  --plan-choose-disable-bg: #C0C0C0;
  --plan-prev-next-color: #FFBE00;
  --plan-table-header-bg: #FFBE00;
  --plan-table-header-disable-bg: #F7FAFB;
  --plan-table-row-bg: #FFF9E8;
  --plan-table-row-disable-bg: #EAEAEA;
  --plan-table-row-odd-disable-bg: #F7FAFB;
  --plan-table-row-even-disable-bg: #F7FAFB;
  --plan-table-column-hover-bg: #FFEDB9;
  --plan-table-price-row-bg: #FFE18B;
  --plan-table-border-color: #EAEAEA;
  --plan-table-price-row-disable-bg: #DFE2E8;
  --plan-card-border-color: #FFBE00;
  --plan-card-border-disable-color: #C0C0C0;
  /* Ai */
  --ai-bg-color: #5D86FF;
  --ai-edit-color: #FFFFFF;
  --ai-undo-color: #6C6C6C;
  /* Toast */
  --toast-info: #5D86FF;
  --toast-warning: #FFBE00;
  --toast-error: #F02E00;
  --toast-success: #19C964;
  --toast-icon-info: #E8EEFF;
  --toast-icon-warning: #FFE18B;
  --toast-icon-error: #FFC1B2;
  --toast-icon-success: #DFFBEB;
  --toast-shadow: #BFC4D2;
  /* Progress */
  --progress-bg: #FFD65D;
  --progress-success-bg: #19C964;
  /* Button */
  --button-warning-bg: #FFBE00;
  --button-warning-active-bg: #FFCA2E;
  --button-success-bg: #19C964;
  --button-success-active-bg: #22E474;
  --button-danger-bg: #C92700;
  --button-danger-active-bg: #F02E00;
  --button-secondary-bg: #B2BFDB;
  --button-secondary-active-bg: #D9DFED;
  --button-primary-bg: #0041FF;
  --button-primary-active-bg: #2E64FF;
  --button-light-bg: #DFE2E8;
  --button-light-active-bg: #D9DFED;
  --button-dark-bg: #374B76;
  --button-dark-active-bg: #48639C;
  --button-return-color: #969696;
  --button-edit-icon-color: #2E64FF;
  --button-disable-bg: #EAEAEA;
  --button-disable-color: #969696;
  /* Step */
  --step-disabled-bg: #D9DFED;
  --step-border-disabled-color: #D9DFED;
  --step-active-bg: #5D86FF;
  --step-active-border-color: #B9CBFF;
  --step-title-color: #374B76;
  /* Table */
  --table-header-bg: #E8EEFF;
  --table-pagination-recode-text: #969696;
  --table-pagination-bg: #DFE2E8;
  --table-pagination-active-bg: #5D86FF;
  --table-pagination-active-text: #FFFFFF;
  --table-th-text: #000000;
  --table-td-text: #000000;
  --table-action-text: #000000;
  --table-action-bg: #D9DFED;
  --table-action-bg-hover: #BFC4D2;
  --table-action-bg-dots: #969696;
  --table-filter-bg: #DFE2E8;
  --table-filter-input-bg: #FFFFFF;
  --table-filter-drop-down-hover: #D9DFED;
  --table-filter-button-active-text: #374B76;
  --table-filter-button-bg: #B2BFDB;
  --table-filter-button-active-bg: #D9DFED;
  --table-filter-applied: #5D86FF;
  --table-filter-applied-icon: #5D86FF;
  --table-drop-down-icon: #5D86FF;
  --table-mobile-value-color: #969696;
  /* Tab */
  --tab-border-color: #EAEAEA;
  --tab-border-active-color: #000000;
  --tab-color: #000000;
  --tab-bg: #EAEAEA;
  --tab-active-bg: #131A29;
  --tab-active-color: #131A29;
  --tab-badge-bg: #FFBE00;
  --tab-badge-color: #000000;
  --tab-active-badge-bg: #2E64FF;
  --tab-active-badge-color: #FFFFFF;
  /* Ticket */
  --ticket-admin-bg: #8C9FCA;
  --ticket-client-bg: #5D86FF;
  --ticket-owner-info-color: #969696;
  /* Payment */
  --payment-price-color: #19C964;
  --payment-add-fund-discount-badge-bg: #5D86FF;
  --payment-add-fund-per-price-color: #FFBE00;
  /* Developers */
  --help-menu-active-bg: #0041FF;
  --help-submenu-active-color: #5D86FF;
  --help-submenu-bg: #E8EEFF;
  --help-api-color: #374B76;
  --help-list-bg: #EAEAEA;
  /* Dashboard */
  --dashboard-red-bg: #FFC1B2;
  --dashboard-green-bg: #81EFB0;
  --dashboard-orange-bg: #FFD65D;
  --dashboard-blue-bg: #8BA9FF;
  --dashboard-red-color: #FF4417;
  --dashboard-green-color: #19C964;
  --dashboard-orange-color: #FFBE00;
  --dashboard-blue-color: #5D86FF;
  /* Footer */
  --footer-bg: #DFE2E8;
  --footer-color: #25324F;
  /* Skeleton */
  --skeleton-color-1: #EAEAEA;
  --skeleton-color-2: #F7FAFB;
  --skeleton-color-3: #D9DFED;
  --skeleton-color-4: #D9DFED;
  /* Logo */
  --logo-bg: #FFBE00;
  --logo-fill-color: #000000;
  --logo-shape-color: #000000;
  /* Payamak sefid */
  --ps-dark-btn-bg: #25324F;
  --ps-dark-active-btn-bg: #48639C;
  --ps-form-input-readonly-color: #969696;
  --ps-title-bg: #5D86FF;
}

[color-scheme=dark] {
  /* Common */
  --black: #000000;
  --white: #FFFFFF;
  --text: #FFFFFF;
  --border: #374B76;
  --border-2: #657FB8;
  --shadow: transparent;
  --background-body: #131A29;
  --background-container: #25324F;
  --background-disable: #131A29;
  --primary-link: #8BA9FF;
  --placeholder: #969696;
  --cart-1-color: #FFFFFF;
  --cart-2-color: #D9DFED;
  --gray-description-background: #374B76;
  --uploaded-title: #B2BFDB;
  --support-file: #B2BFDB;
  /* Help */
  --help-close-btn-bg: #C92700;
  --help-link-color: #22E474;
  /* Code Block */
  --code-block-bg: #25324F;
  --code-block-border-color: #C0C0C0;
  --code-block-color: #FFFFFF;
  /* FAQ */
  --FAQ-index-bg: #856404;
  --FAQ-index-active-bg: #FFBE00;
  --FAQ-index-active-color: #000000;
  --FAQ-index-color: #FFFFFF;
  --FAQ-dropdown-icon-color: #B9CBFF;
  /* Loading */
  --loading-border-color-1: #B9CBFF;
  /* Header */
  --header-toggle-switch-color: #0041FF;
  --header-toggle-switch-bg: #B9CBFF;
  --header-toggle-swich-dark-bg: #0041FF;
  --header-items-bg: #374B76;
  --header-items-color: #FFFFFF;
  /* Sidebar */
  --sidebar-menu-color: #FFFFFF;
  --sidebar-menu-close-btn-color: #FFFFFF;
  --sidebar-menu-title-color: #FFFFFF;
  --sidebar-menu-sub-color: #B2BFDB;
  --sidebar-menu-border-color: #5D86FF;
  /* Dropdown */
  --dropdown-menu-hover-bg: #25324F;
  --dropdown-menu-shadow-color: #181818;
  /* Modal */
  --modal-bg: #374B76;
  --modal-backdrop-bg: rgba($dark-blue-1, 1);
  --modal-input-bg: #25324F;
  /* Scroll */
  --scrollbar: #374B76;
  --scrollbar-thumb: #B2BFDB;
  /* Alert */
  --alert-blue-bg: #172240;
  --alert-red-bg: #320812;
  --alert-green-bg: #1A3023;
  --alert-yellow-bg: #282804;
  --alert-blue-color: #5D86FF;
  --alert-red-color: #FF4417;
  --alert-yellow-color: #FFBE00;
  --alert-green-color: #81EFB0;
  /* Notification */
  --notification-bg: #FFBE00;
  /* Form */
  --form-text-error-color: #FF4417;
  --form-input-readonly-color: #EAEAEA;
  --form-checkbox-bg: #DFE2E8;
  --form-checkbox-active-bg: #5D86FF;
  --form-checkbox-checked-bg: #FFFFFF;
  --form-radio-bg: #FFFFFF;
  --form-radio-color: #EAEAEA;
  --form-radio-disabled-color: #657FB8;
  --form-radio-active-bg: #5D86FF;
  --form-radio-checked-bg: #FFFFFF;
  --form-search-input-icon-color: #969696;
  --form-sms-count-color: #969696;
  --form-switch-active-bg: #5D86FF;
  --form-input-color: #FFFFFF;
  --form-input-error-color: #F02E00;
  --form-input-bg: #374B76;
  --form-select-hover-bg: #25324F;
  --form-input-placeholder-color: #969696;
  --form-close-icon-color: #FFFFFF;
  /* Campaign */
  --campaign-parameter-link-color: #19C964;
  --campaign-link-color: #969696;
  --campaign-blue-title-color: #5D86FF;
  --campaign-schadule-tag-color: #5D86FF;
  --campaign-schadule-tag-icon-color: #5D86FF;
  --campaign-abtest-range-color: #FFFFFF;
  --campaign-abtest-range-1-bg: #0041FF;
  --campaign-abtest-range-2-bg: #2E64FF;
  --campaign-abtest-range-3-bg: #5D86FF;
  --campaign-abtest-range-4-bg: #8BA9FF;
  --campaign-abtest-range-5-bg: #B9CBFF;
  --campaign-abtest-range-5-color: #0041FF;
  --campaign-abtest-tag-color: #EAEAEA;
  --campaign-abtest-tag-active-color: #5D86FF;
  --campaign-abtest-tag-active-icon-color: #5D86FF;
  --campaign-abtest-shorlink-disabled-color: #969696;
  --campaign-title-color: #EAEAEA;
  --campaign-abtest-tag-icon-color: #FFFFFF;
  --campaign-abtest-add-category-color: #E8EEFF;
  --campaign-abtest-percent-bg: #EAEAEA;
  --campaign-abtest-percent-handler-color: #EAEAEA;
  /* BankNumber */
  --banknumber-abtest-shorlink-disabled-color: #5D86FF;
  --banknumber-color: #EAEAEA;
  --banknumber-count-number-color: #969696;
  /* Plan */
  --plan-free-color: #19C964;
  --plan-free-bg: #424242;
  --plan-bronze-color: #F02E00;
  --plan-bronze-bg: #424242;
  --plan-silver-color: #374B76;
  --plan-silver-bg: #424242;
  --plan-gold-color: #FFBE00;
  --plan-gold-bg: #424242;
  --plan-current-bg: #8089A5;
  --plan-disabled-color: #B2BFDB;
  --plan-disabled-bg: #EAEAEA;
  --plan-active-switch-bg: #657FB8;
  --plan-item-disable-bg: #2E2E2E;
  --plan-best-offer-bg: #FFBE00;
  --plan-best-offer-disable-bg: #FFFFFF;
  --plan-best-offer-color: #000000;
  --plan-title-bg: #FFD65D;
  --plan-title-color: #FFFFFF;
  --plan-title-disable-bg: #C0C0C0;
  --plan-old-price-color: #969696;
  --plan-discount-percentage-bg: #FFBE00;
  --plan-discount-percentage-disable-bg: #C0C0C0;
  --plan-discount-percentage-color: #000000;
  --plan-more-info-bg: #374B76;
  --plan-more-info-disable-bg: #424242;
  --plan-more-info-color: #FFFFFF;
  --plan-choose-bg: #FFBE00;
  --plan-choose-disable-bg: #C0C0C0;
  --plan-prev-next-color: #FFBE00;
  --plan-table-header-bg: #FFBE00;
  --plan-table-header-disable-bg: #2E2E2E;
  --plan-table-row-bg: #374B76;
  --plan-table-row-disable-bg: #F7FAFB;
  --plan-table-row-odd-disable-bg: #424242;
  --plan-table-row-even-disable-bg: #2E2E2E;
  --plan-table-column-hover-bg: #374B76;
  --plan-table-price-row-bg: #FFE18B;
  --plan-table-border-color: #131A29;
  --plan-table-price-row-disable-bg: #DFE2E8;
  --plan-card-border-color: #FFBE00;
  --plan-card-border-disable-color: #C0C0C0;
  /* Ai */
  --ai-bg-color: #FFBE00;
  --ai-color: #FFFFFF;
  /* Toast */
  --toast-info: #5D86FF;
  --toast-warning: #FFBE00;
  --toast-error: #F02E00;
  --toast-success: #19C964;
  --toast-icon-info: #E8EEFF;
  --toast-icon-warning: #FFE18B;
  --toast-icon-error: #FFC1B2;
  --toast-icon-success: #DFFBEB;
  --toast-shadow: transparent;
  /* Progress */
  --progress-bg: #FFD65D;
  --progress-success-bg: #19C964;
  /* Button */
  --button-warning-bg: #FFBE00;
  --button-warning-active-bg: #FFCA2E;
  --button-success-bg: #19C964;
  --button-success-active-bg: #22E474;
  --button-danger-bg: #C92700;
  --button-danger-active-bg: #F02E00;
  --button-secondary-bg: #B2BFDB;
  --button-secondary-active-bg: #D9DFED;
  --button-primary-bg: #0041FF;
  --button-primary-active-bg: #2E64FF;
  --button-light-bg: #657FB8;
  --button-light-active-bg: #48639C;
  --button-dark-bg: #657FB8;
  --button-dark-active-bg: #48639C;
  --button-edit-icon-color: #2E64FF;
  --button-return-color: #EAEAEA;
  --button-disable-bg: #EAEAEA;
  --button-disable-color: #969696;
  /* Step */
  --step-disabled-bg: #374B76;
  --step-border-disabled-color: #374B76;
  --step-active-bg: #5D86FF;
  --step-active-border-color: #5D86FF;
  --step-title-color: #EAEAEA;
  /* Table */
  --table-header-bg: #374B76;
  --table-pagination-recode-text: #969696;
  --table-pagination-bg: #25324F;
  --table-pagination-active-bg: #48639C;
  --table-pagination-active-text: #FFFFFF;
  --table-th-text: #B2BFDB;
  --table-td-text: #E8EEFF;
  --table-action-text: #B2BFDB;
  --table-action-bg: #374B76;
  --table-action-bg-hover: #657FB8;
  --table-action-bg-dots: #B2BFDB;
  --table-filter-bg: #374B76;
  --table-filter-input-bg: #657FB8;
  --table-filter-drop-down-hover: #374B76;
  --table-filter-button-active-text: #FFFFFF;
  --table-filter-button-bg: #374B76;
  --table-filter-button-active-bg: #48639C;
  --table-filter-applied: #B9CBFF;
  --table-filter-applied-icon: #8BA9FF;
  --table-drop-down-icon: #B9CBFF;
  --table-mobile-value-color: #B2BFDB;
  /* Tab */
  --tab-border-color: #374B76;
  --tab-border-active-color: #FFFFFF;
  --tab-color: #FFFFFF;
  --tab-bg: #374B76;
  --tab-active-bg: #FFFFFF;
  --tab-active-color: #131A29;
  --tab-badge-bg: #FFBE00;
  --tab-badge-color: #000000;
  --tab-active-badge-bg: #2E64FF;
  --tab-active-badge-color: #FFFFFF;
  /* Ticket */
  --ticket-admin-bg: #374B76;
  --ticket-client-bg: #5D86FF;
  --ticket-owner-info-color: #969696;
  /* Payment */
  --payment-price-color: #19C964;
  --payment-add-fund-discount-badge-bg: #8BA9FF;
  --payment-add-fund-per-price-color: #FFBE00;
  /* Developers */
  --help-menu-active-bg: #5D86FF;
  --help-submenu-active-color: #FFFFFF;
  --help-submenu-bg: #374B76;
  --help-api-color: #FFFFFF;
  --help-list-bg: #374B76;
  /* Dashboard */
  --dashboard-red-bg: #FFC1B2;
  --dashboard-green-bg: #81EFB0;
  --dashboard-orange-bg: #FFD65D;
  --dashboard-blue-bg: #657FB8;
  --dashboard-red-color: #FF4417;
  --dashboard-green-color: #19C964;
  --dashboard-orange-color: #FFBE00;
  --dashboard-blue-color: #5D86FF;
  /* Footer */
  --footer-bg: #374B76;
  --footer-color: #FFFFFF;
  /* Skeleton */
  --skeleton-color-1: #374B76;
  --skeleton-color-2: #25324F;
  --skeleton-color-3: #657FB8;
  --skeleton-color-4: #374B76;
  /* Logo */
  --logo-bg: #FFBE00;
  --logo-fill-color: #FFFFFF;
  --logo-shape-color: #25324F;
  /* Payamak sefid */
  --ps-dark-btn-bg: #25324F;
  --ps-dark-active-btn-bg: #48639C;
  --ps-form-input-readonly-color: #969696;
}

* {
  outline: none !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

:focus, :active, :enabled {
  outline: none !important;
}

ul {
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style-type: none;
}

p {
  margin: 0;
  padding-bottom: 0.25rem;
}

b {
  font-weight: bold;
}

a:hover {
  outline: 0;
  text-decoration: none;
  color: unset;
}

a:active, a:focus {
  outline: 0;
  text-decoration: none;
}

button {
  outline: none;
  background-color: initial;
}

.btn:focus, .filter-btn:focus, .btn.focus, .focus.filter-btn {
  outline: 0;
  box-shadow: none !important;
}

.clear {
  clear: both;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

:root {
  font-size: 16px;
}

body * {
  font-family: "IRanYekan", sans-serif;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background: var(--background-body);
  color: var(--text);
  min-height: 100vh;
  text-align: right;
  direction: rtl;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 16px;
}

h2 {
  font-size: 14px;
}

h3 {
  font-size: 12px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 16px;
  padding-bottom: 0.25rem;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
}
@media only screen and (max-width : 575px) {
  p {
    font-size: 14px;
  }
}

pre {
  overflow: auto hidden;
  direction: ltr !important;
}

strong, b {
  font-weight: 700;
}

::placeholder {
  color: var(--placeholder) !important;
  font-size: 14px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.right {
  float: right;
}

.left {
  float: left;
}

.pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-help {
  cursor: help;
}

.push-left {
  margin-right: auto;
}

.push-right {
  margin-left: auto;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.fa-1-5x {
  font-size: 1.5rem;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-22 {
  font-size: 22px;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-40 {
  font-size: 40px !important;
}

.mbsc-fr-w,
.mbsc-cal-picker {
  font-family: "IRanYekan", sans-serif, sans-serif !important;
  background: var(--background-container) !important;
  color: var(--text) !important;
}

.mt-6 {
  margin-top: 2rem;
}

.mb-6 {
  margin-bottom: 2rem;
}

.my-6 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.radius-10 {
  border-radius: 10px;
}

@media (min-width: 768px) {
  .mb-md-6, .my-md-6 {
    margin-bottom: 2rem !important;
  }

  .mt-md-6, .my-md-6 {
    margin-top: 2rem !important;
  }
}
hr {
  margin: 1rem;
  border-top: 1px solid var(--border);
}

/*#region scrollbar*/
* {
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar);
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  background-color: var(--scrollbar);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--scrollbar);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.45);
  background-color: var(--scrollbar-thumb);
  border-radius: 0.5rem;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 300;
  font-size: 14px;
  color: var(--placeholder) !important;
}

.c-wrapper {
  display: flex;
  flex-direction: column;
}

.c-header {
  padding: 0 2rem;
  display: flex;
}
@media only screen and (max-width : 575px) {
  .c-header {
    padding: 0 1rem;
    margin-right: -2px;
  }
}

.c-main {
  margin: 2rem 2rem 0;
  flex-grow: 1;
}
@media only screen and (max-width: 1320px) {
  .c-main {
    margin: 1rem 1rem 0;
  }
}

.box-container {
  padding: 1.5rem;
  border-radius: 14px;
  -webkit-box-shadow: 0 16px 45px var(--shadow);
  -moz-box-shadow: 0 16px 45px var(--shadow);
  box-shadow: 0 16px 45px var(--shadow);
  background-color: var(--background-container);
}

.show-details {
  cursor: pointer;
  text-align: left;
  margin-top: 0.5rem;
  padding-bottom: 0;
  color: var(--primary-link);
}
@media only screen and (max-width : 575px) {
  .show-details {
    font-size: 16px;
  }
  .show-details span {
    font-size: inherit;
  }
}

/*#region tab*/
.tab-div {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1.5rem;
  width: fit-content;
}
@media only screen and (max-width : 575px) {
  .tab-div {
    width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.tab-div:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  background: var(--tab-bg);
}
.tab-div .title-tab a, .tab-div .title-tab > span {
  padding: 0 0 1.5rem 0;
  margin-left: 1.5rem;
  color: var(--tab-color);
  cursor: pointer;
  display: block;
}
.tab-div .title-tab a.active, .tab-div .title-tab > span.active {
  color: var(--tab-color);
  font-weight: 700;
  position: relative;
}
.tab-div .title-tab a.active:before, .tab-div .title-tab > span.active:before {
  content: " ";
  position: absolute;
  width: 100%;
  border-radius: 0.5rem;
  height: 3px;
  background: var(--tab-active-bg);
  bottom: -1px;
  right: 0;
}
.tab-div .title-tab:last-child a, .tab-div .title-tab:last-child > span {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .descriptor button {
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width : 575px) {
  .descriptor .d-flex {
    flex-wrap: wrap;
    width: 100%;
  }
  .descriptor button {
    margin: 1rem 0 0 0 !important;
    min-width: 100%;
  }
}

/*#select box */
.row-box {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -0.75rem 0;
}

.main-box {
  background-color: var(--background-container);
  border-radius: 1rem;
  height: 220px;
  width: 220px;
  cursor: pointer;
  transition: all 400ms ease;
  padding: 1rem 0.8rem 0 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.75rem 1.5rem 0.75rem;
  -webkit-box-shadow: 0 16px 45px var(--shadow);
  -moz-box-shadow: 0 16px 45px var(--shadow);
  box-shadow: 0 16px 45px var(--shadow);
}
@media screen and (min-width: 1501px) and (max-width: 1600px) {
  .main-box {
    width: calc(100% / 5 - 1.5rem);
  }
}
@media screen and (min-width: 1301px) and (max-width: 1500px) {
  .main-box {
    width: calc(100% / 4 - 1.5rem);
  }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
  .main-box {
    width: calc(100% / 3 - 1.5rem);
  }
}
@media screen and (min-width: 466px) and (max-width: 767px) {
  .main-box {
    width: calc(100% / 2 - 1.5rem);
  }
}
@media screen and (max-width: 465px) {
  .main-box {
    width: 100%;
  }
}
.main-box img {
  max-width: 100%;
  max-height: 150px;
}
.main-box p:nth-child(1) {
  color: var(--cart-1-color);
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  padding: 0;
  text-align: center;
}
.main-box p:nth-child(2) {
  color: var(--cart-2-color);
  line-height: 20px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  height: 44px;
}
.main-box:hover {
  transform: translateY(-3px);
}
.main-box > div.disable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 1rem;
  background-color: var(--background-disable);
  opacity: 0.5;
}

.info-box {
  background-color: var(--gray-description-background);
  padding: 1rem;
  border-radius: 14px;
  min-height: 60px;
}
.info-box p {
  white-space: pre-line;
  line-height: 1.5;
  padding-bottom: 0;
}
@media only screen and (max-width : 575px) {
  .info-box p {
    font-size: 16px;
  }
}

.selected-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
}
.selected-contact span {
  font-size: 0.875rem;
  padding-bottom: 0;
}
@media only screen and (max-width : 575px) {
  .selected-contact span {
    font-size: 0.75rem;
  }
}

@keyframes move-forward {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}
.arrow-left-move {
  animation: 1s ease-in infinite move-forward;
}

.send-confirm-title,
.sender-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .send-confirm-title,
.sender-info {
    justify-content: flex-start;
  }
}

.without-data-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn-row-wrapper button {
  min-width: fit-content;
  margin-right: 0.5rem;
}

.btn, .filter-btn {
  min-width: 160px;
  height: 48px;
  background: transparent;
  outline: none !important;
  border-radius: 14px !important;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1em;
  line-height: calc(48px - 1rem);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
.btn i, .filter-btn i {
  font-size: 1.5rem;
  margin-left: 12px;
}
@media only screen and (max-width : 575px) {
  .btn, .filter-btn {
    min-width: 135px;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
  }
  .btn i, .filter-btn i {
    font-size: 1rem;
    margin-left: 6px;
  }
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .filter-btn {
  min-width: 120px !important;
  height: 40px !important;
}

.btn-wide {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.btn-full {
  width: 100%;
}

.warning-btn {
  background-color: var(--button-warning-bg);
  border-color: var(--button-warning-bg);
  color: var(--black) !important;
}
.warning-btn:active {
  background-color: var(--button-warning-active-bg);
  border-color: var(--button-warning-active-bg);
}
.warning-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.success-btn {
  background-color: var(--button-success-bg);
  border-color: var(--button-success-bg);
  color: var(--white) !important;
}
.success-btn:active {
  background-color: var(--button-success-active-bg);
  border-color: var(--button-success-active-bg);
}
.success-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.danger-btn {
  background-color: var(--button-danger-bg);
  border-color: var(--button-danger-bg);
  color: var(--white) !important;
}
.danger-btn:active {
  background-color: var(--button-danger-active-bg);
  border-color: var(--button-danger-active-bg);
}
.danger-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.secondary-btn {
  background-color: var(--button-secondary-bg);
  border-color: var(--button-secondary-bg);
  color: var(--black) !important;
}
.secondary-btn:active {
  background-color: var(--button-secondary-active-bg);
  border-color: var(--button-secondary-active-bg);
}
.secondary-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.primary-btn {
  background-color: var(--button-primary-bg);
  border-color: var(--button-primary-bg);
  color: var(--white) !important;
}
.primary-btn:active {
  background-color: var(--button-primary-active-bg);
  border-color: var(--button-primary-active-bg);
}
.primary-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.light-btn {
  background-color: var(--button-light-bg);
  border-color: var(--button-light-bg);
  color: var(--text) !important;
}
.light-btn:active {
  background-color: var(--button-light-active-bg);
  border-color: var(--button-light-active-bg);
}
.light-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.dark-btn {
  background-color: var(--button-dark-bg);
  border-color: var(--button-dark-bg);
  color: var(--white) !important;
}
.dark-btn:active {
  background-color: var(--button-dark-active-bg);
  border-color: var(--button-dark-active-bg);
}
.dark-btn:disabled {
  background-color: var(--button-disable-bg);
  border-color: var(--button-disable-bg);
  color: var(--button-disable-color) !important;
}

.link-btn {
  font-weight: 300;
  color: var(--primary-link);
  text-decoration: none;
  cursor: pointer;
}
.link-btn:hover {
  color: var(--primary-link);
  text-decoration: underline;
}

.btn-loading {
  cursor: progress;
  opacity: 0.5;
}
.btn-loading:hover {
  cursor: progress;
  opacity: 0.5;
}

.return-back-btn {
  font-weight: 300;
  color: var(--button-return-color);
  border: none;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.return-back-btn:before {
  content: "";
  font-family: "icomoon", serif;
  transform: rotate(180deg);
}

.edit-btn {
  border: 0;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  color: var(--button-edit-icon-color);
  font-size: 0.875rem;
}
.edit-btn i {
  font-size: 19px;
  margin-left: 0.25rem;
  font-weight: 700;
}

.filter-btn {
  padding: 0.5rem 1rem;
  min-width: 118px;
  height: 48px;
}
.filter-btn:after {
  display: none;
}
.filter-btn i {
  font-size: 1.3rem;
}
@media only screen and (max-width : 575px) {
  .filter-btn {
    min-width: 48px !important;
    margin-bottom: 0.75rem;
  }
  .filter-btn i {
    margin: 0 !important;
    font-size: 1.3rem !important;
  }
  .filter-btn span {
    display: none;
  }
}

.plan-btn {
  min-width: 120px;
  border-radius: 14px;
  color: var(--black);
  background-color: var(--plan-choose-bg);
}
.plan-btn.disable {
  background-color: var(--plan-choose-disable-bg);
}

.form-group {
  position: relative;
  margin-bottom: 1.2rem;
}

input, select, textarea {
  background: var(--form-input-bg);
  border: 2px solid var(--form-input-bg);
  width: 100%;
  border-radius: 14px;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-weight: 300;
  position: relative;
  z-index: 5;
  color: var(--form-input-color);
}
input.white-control, select.white-control, textarea.white-control {
  background-color: var(--table-filter-input-bg);
  border-color: var(--table-filter-input-bg);
}
input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: var(--form-input-placeholder-color);
}
input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: var(--form-input-placeholder-color);
}
input::placeholder, select::placeholder, textarea::placeholder {
  color: var(--form-input-placeholder-color);
}
input:read-only:not(.mbsc-comp), select:read-only:not(.mbsc-comp), textarea:read-only:not(.mbsc-comp) {
  color: var(--form-input-readonly-color);
  cursor: no-drop;
}
input:-webkit-autofill, input:-internal-autofill-selected, select:-webkit-autofill, select:-internal-autofill-selected, textarea:-webkit-autofill, textarea:-internal-autofill-selected {
  box-shadow: 0 0 100px var(--form-input-bg) inset;
  background: transparent;
}
input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-internal-autofill-selected:hover, input:-internal-autofill-selected:focus, select:-webkit-autofill:hover, select:-webkit-autofill:focus, select:-internal-autofill-selected:hover, select:-internal-autofill-selected:focus, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-internal-autofill-selected:hover, textarea:-internal-autofill-selected:focus {
  box-shadow: 0 0 100px var(--form-input-bg) inset;
  background: transparent;
}

label {
  display: block;
  font-size: 1rem;
  margin: 0.5rem 0;
  text-align: right;
}

textarea {
  resize: none;
  min-height: 10rem;
}

.input-error {
  border-color: var(--form-input-error-color);
}

.ng-select {
  min-width: 140px;
}
.ng-select .ng-select-container {
  background: var(--form-input-bg) !important;
  width: 100% !important;
  border: 0;
  border-color: unset !important;
  border-radius: 14px !important;
  height: 2.5rem !important;
  padding: 0.5rem 1rem;
  box-shadow: none !important;
  color: var(--form-input-color);
}
.ng-select .ng-select-container .ng-value-container {
  padding: 0 !important;
}
.ng-select .ng-select-container input {
  height: initial;
}
.ng-select .ng-select-container .ng-value-label {
  font-weight: 300;
}
.ng-select .ng-dropdown-panel.ng-select-bottom {
  top: 3rem;
  border-radius: 14px;
  border: 0;
  background: var(--form-input-bg);
  padding: 1rem 0;
  box-shadow: 2px 15px 24px 0 var(--dropdown-menu-shadow-color);
}
.ng-select .ng-dropdown-panel.ng-select-top {
  bottom: 3rem;
  border-radius: 14px;
  border: 0;
  background: var(--form-input-bg);
  padding: 1rem 0;
  box-shadow: 2px 15px 24px 0 var(--dropdown-menu-shadow-color);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: transparent;
  border-radius: 14px !important;
  margin-bottom: 0.25rem;
  color: var(--text);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: var(--form-select-hover-bg);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: var(--form-input-placeholder-color);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-weight: 300;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--form-select-hover-bg);
  color: var(--text);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected span {
  font-weight: 300 !important;
}
.ng-select.white-control .ng-select-container {
  background: var(--table-filter-input-bg) !important;
}
.ng-select.white-control .ng-dropdown-panel.ng-select-bottom {
  background: var(--table-filter-input-bg) !important;
}
.ng-select.white-control .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-select.white-control .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: var(--table-filter-drop-down-hover);
}

.input-prepend {
  display: flex;
  align-items: center;
}
.input-prepend-box {
  flex: 3rem;
  margin-right: 0.5rem;
  background: var(--form-input-bg);
  height: 2.5rem;
  border-radius: 14px;
  line-height: 2.5rem;
  text-align: center;
  padding: 0 0.5rem;
}
.input-prepend-box span {
  font-weight: 300;
  font-size: 1rem;
}

.search-wrapper {
  margin-bottom: 0.5rem;
  position: relative;
}
.search-wrapper__input {
  padding-right: 50px;
  width: 100%;
}
.search-wrapper__icon {
  position: absolute;
  right: 15px;
  font-size: 1.5rem;
  color: var(--form-search-input-icon-color);
  top: 50%;
  transform: translateY(-50%);
  z-index: 6;
}

.input-container {
  position: relative;
}
.input-container .hint-password {
  z-index: 6;
  position: absolute;
  top: 9px;
  left: 18px;
  cursor: pointer;
}
.input-container .hint-password i {
  font-size: 1.5rem;
  color: var(--form-close-icon-color);
}

.modal {
  background: var(--modal-backdrop-bg);
}
.modal input:not([type=checkbox]), .modal textarea, .modal .ng-select .ng-select-container {
  background-color: var(--modal-input-bg) !important;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 385px !important;
  }
}
.modal-content {
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--modal-bg);
}

.modal-header {
  display: block;
  border: none;
  padding: 0;
}
.modal-header img {
  height: 100%;
  padding-bottom: 1rem;
}

.modal-body {
  padding: 1px;
}

.modal-footer {
  display: block;
  border: none;
  padding: 1rem 0 0 0;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.modal-table {
  width: 100%;
  outline: 0;
  margin-bottom: 0;
}
.modal-table__item {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid var(--border-2);
}
.modal-table__item:last-child {
  border-bottom: none;
}
.modal-table__item--header, .modal-table__item--value {
  padding: 1rem;
  text-align: center;
  color: var(--text);
}
.modal-table__item--header {
  text-align: right;
  flex-shrink: 0;
  max-width: 60%;
}
.modal-table__item--value {
  word-break: break-word;
  white-space: pre-line;
  max-height: 110px;
  overflow-y: auto;
  text-align: left;
  flex-grow: 1;
}
@media only screen and (max-width : 575px) {
  .modal-table__item--header, .modal-table__item--value {
    font-size: 14px;
  }
}

.transparent-modal .modal-content {
  padding: 0;
  background: transparent;
  border: none;
}

.alert {
  border-radius: 14px;
  padding: 0.8rem;
  display: flex;
  text-align: right;
  align-items: flex-start;
  margin: 0;
}
.alert-warning {
  color: var(--alert-yellow-color) !important;
  background-color: var(--alert-yellow-bg) !important;
  border-color: var(--alert-yellow-bg) !important;
}
.alert-success {
  color: var(--alert-green-color) !important;
  background-color: var(--alert-green-bg) !important;
  border-color: var(--alert-green-bg) !important;
}
.alert-info {
  color: var(--alert-blue-color) !important;
  background-color: var(--alert-blue-bg) !important;
  border-color: var(--alert-blue-bg) !important;
}
.alert-danger {
  color: var(--alert-red-color) !important;
  background-color: var(--alert-red-bg) !important;
  border-color: var(--alert-red-bg) !important;
}
.alert i {
  font-size: 1.5rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}
.alert p {
  padding: 4px !important;
  font-weight: 400;
  line-height: 1.5;
}
.alert-slim {
  padding: 0.5rem 1rem;
}
.alert-slim i {
  font-size: 1.25rem;
}
.alert-slim p {
  font-weight: 400;
  font-size: 0.875rem;
}

.params-ltr .params__list, .params__ltr .params__list {
  justify-content: space-between;
}
.params-ltr .params__list__label, .params__ltr .params__list__label, .params-ltr .params__list__value, .params__ltr .params__list__value {
  word-break: break-word;
  max-width: inherit !important;
  flex: inherit !important;
}
.params-ltr .params__list__value, .params__ltr .params__list__value {
  max-width: 60% !important;
  white-space: pre-line;
  text-align: left;
}

.params {
  list-style: none;
  padding: 0;
  border-bottom: none !important;
}
.params__list {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--border);
  margin: 0;
}
.params__list:last-child {
  border-bottom: none;
}
.params__list__label {
  flex: 0 0 24%;
  max-width: 24%;
}
.params__list__value {
  flex: 0 0 76%;
  max-width: 76%;
}
.params__list__label, .params__list__value {
  padding: 1rem 0 1rem 0.5rem;
  font-weight: 400;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .params__list {
    justify-content: space-between;
  }
  .params__list__label {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .params__list__value {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
}
@media only screen and (max-width : 575px) {
  .params__list {
    /*  &__label {
        -webkit-box-flex: 0;
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }

      &__value {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }*/
    justify-content: space-between;
  }
  .params__list__label, .params__list__value {
    word-break: break-word;
    max-width: inherit;
    flex: inherit;
  }
  .params__list__value {
    max-width: 60% !important;
    white-space: pre-line;
    text-align: left;
  }
}
.api-help-wrapper > p {
  color: var(--help-api-color);
  padding: 0;
  font-weight: 400;
  line-height: 28px;
}
.api-help-wrapper > h5 {
  color: var(--help-api-color);
}
.api-help-wrapper .list-item {
  direction: ltr;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.api-help-wrapper .list-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1024px) {
  .api-help-wrapper .list-item {
    flex-direction: column;
  }
  .api-help-wrapper .list-item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.api-help-wrapper .list-key {
  background-color: var(--help-list-bg);
  color: var(--help-api-color);
  font-weight: 400;
  border-radius: 14px;
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  width: 33.3333%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1024px) {
  .api-help-wrapper .list-key {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.api-help-wrapper .list-key button {
  border: 0;
  font-size: 18px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
}
.api-help-wrapper .list-value {
  background-color: var(--help-list-bg);
  color: var(--help-api-color);
  font-weight: 400;
  border-radius: 14px;
  min-height: 2.5rem;
  padding: 0.5rem 1rem 0;
  width: calc(66.6666% - 1rem);
}
.api-help-wrapper .list-value-tabs {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}
.api-help-wrapper .list-value-tabs span {
  padding: 2px 12px;
  cursor: pointer;
  color: var(--help-api-color);
}
.api-help-wrapper .list-value-tabs span.active {
  background: var(--background-container);
  border-radius: 14px;
}
.api-help-wrapper .list-value-tabs .copy-btn {
  border: 0;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
}
@media only screen and (max-width: 1024px) {
  .api-help-wrapper .list-value {
    width: 100%;
  }
}
.api-help-wrapper .list-value input {
  height: 100%;
  font-weight: 400;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: var(--text);
  cursor: default;
}
.api-help-wrapper .list-value > pre {
  padding-bottom: 0.5rem;
  color: var(--text);
  font-size: 1rem;
  margin: 0;
}
.api-help-wrapper .list-value > span {
  display: block;
  width: 100%;
  word-break: break-all;
}

table.custom-table {
  width: 100%;
  color: var(--text);
  text-align: center;
  font-size: 0.875rem;
}
@media only screen and (max-width: 1024px) {
  table.custom-table {
    display: block;
  }
}
table.custom-table thead tr {
  height: 40px;
  background: var(--table-header-bg);
}
table.custom-table thead tr td:first-child {
  border-radius: 0 0.9rem 0.9rem 0;
}
table.custom-table thead tr td:last-child {
  border-radius: 0.9rem 0 0 0.9rem;
}
@media only screen and (max-width: 1024px) {
  table.custom-table thead {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  table.custom-table tbody {
    display: block;
  }
}
table.custom-table tbody tr {
  vertical-align: middle;
  height: 55px;
  border-bottom: 1px solid var(--border);
  font-weight: 300;
}
@media only screen and (max-width: 1024px) {
  table.custom-table tbody tr {
    display: block;
    height: auto;
  }
}
table.custom-table tbody tr td {
  min-width: 60px;
}
@media only screen and (max-width: 1024px) {
  table.custom-table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
    font-size: 0.875rem;
  }
  table.custom-table tbody tr td:before {
    content: attr(data-header);
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icons/icomoon.eot?ybh4q6");
  src: url("../../fonts/icons/icomoon.eot?ybh4q6#iefix") format("embedded-opentype"), url("../../fonts/icons/icomoon.ttf?ybh4q6") format("truetype"), url("../../fonts/icons/icomoon.woff?ybh4q6") format("woff"), url("../../fonts/icons/icomoon.svg?ybh4q6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left2:before {
  content: "⭠";
}

.icon-dots-vertical:before {
  content: "";
}

.icon-plans:before {
  content: "";
}

.icon-eye-slash:before {
  content: "";
}

.icon-add-message:before {
  content: "";
}

.icon-add-user:before {
  content: "";
}

.icon-arrow:before {
  content: "";
}

.icon-calender:before {
  content: "";
}

.icon-folder:before {
  content: "🗁";
}

.icon-cancel:before {
  content: "";
}

.icon-card:before {
  content: "";
}

.icon-chart:before {
  content: "";
}

.icon-chart-line:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-cross:before {
  content: "✕";
}

.icon-circle-add:before {
  content: "";
}

.icon-circle-arrow:before {
  content: "";
}

.icon-circle-info:before {
  content: "";
}

.icon-circle-minus:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-commenr:before {
  content: "";
}

.icon-contact:before {
  content: "";
}

.icon-contact-us:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-dark:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-download-file:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-features:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-form:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-law:before {
  content: "";
}

.icon-light:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-notification:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-mail:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-report:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-setting:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-view:before {
  content: "";
}

.icon-wallet:before {
  content: "";
}

.icon-activation:before {
  content: "";
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* Base colors */
.mbsc-cloak {
  visibility: hidden !important;
}

/* Empty view */
.mbsc-empty {
  text-align: center;
  margin: 3em;
  color: inherit;
}

.mbsc-empty h3 {
  margin: 0.666666em 0;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  font-weight: normal;
  font-family: inherit;
}

.mbsc-empty p {
  margin: 1em 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
}

.mbsc-anim-trans .mbsc-fr-scroll {
  overflow: hidden;
}

.mbsc-anim-trans-flip .mbsc-fr-persp,
.mbsc-anim-trans-swing .mbsc-fr-persp {
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.mbsc-anim-trans .mbsc-fr-popup,
.mbsc-anim-trans .mbsc-fr-overlay {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.mbsc-anim-in .mbsc-fr-popup,
.mbsc-anim-in .mbsc-fr-overlay {
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 225ms;
  animation-timing-function: ease-out;
  animation-duration: 225ms;
}

.mbsc-anim-out .mbsc-fr-popup,
.mbsc-anim-out .mbsc-fr-overlay {
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 195ms;
  animation-timing-function: ease-in;
  animation-duration: 195ms;
}

.mbsc-anim-in .mbsc-fr-overlay {
  -webkit-animation-name: mbsc-anim-f-in;
  animation-name: mbsc-anim-f-in;
}

.mbsc-anim-out .mbsc-fr-overlay {
  -webkit-animation-name: mbsc-anim-f-out;
  animation-name: mbsc-anim-f-out;
}

.mbsc-anim-flip,
.mbsc-anim-swing,
.mbsc-anim-slidehorizontal,
.mbsc-anim-slidevertical,
.mbsc-anim-slidedown,
.mbsc-anim-slideup,
.mbsc-anim-fade {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateX(0);
  backface-visibility: hidden;
  transform: translateX(0);
}

.mbsc-anim-swing,
.mbsc-anim-slidehorizontal,
.mbsc-anim-slidevertical,
.mbsc-anim-slidedown,
.mbsc-anim-slideup,
.mbsc-anim-fade {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.mbsc-anim-flip,
.mbsc-anim-pop {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.mbsc-anim-in .mbsc-anim-pop {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-p-in;
  -webkit-animation-duration: 100ms;
  -webkit-transform: scale(1);
  animation-name: mbsc-anim-p-in;
  animation-duration: 100ms;
  transform: scale(1);
}

.mbsc-anim-out .mbsc-anim-pop {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-p-out;
  -webkit-animation-duration: 150ms;
  animation-name: mbsc-anim-p-out;
  animation-duration: 150ms;
}

.mbsc-anim-trans-pop .mbsc-fr-overlay {
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
}

.mbsc-anim-in .mbsc-anim-flip {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-fl-in;
  -webkit-transform: scale(1);
  animation-name: mbsc-anim-fl-in;
  transform: scale(1);
}

.mbsc-anim-out .mbsc-anim-flip {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-fl-out;
  animation-name: mbsc-anim-fl-out;
}

.mbsc-anim-in .mbsc-anim-swing {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-sw-in;
  -webkit-transform: scale(1);
  transform: scale(1);
  animation-name: mbsc-anim-sw-in;
}

.mbsc-anim-out .mbsc-anim-swing {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-sw-out;
  animation-name: mbsc-anim-sw-out;
}

.mbsc-anim-in .mbsc-anim-slidehorizontal {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-sh-in;
  -webkit-transform: scale(1);
  transform: scale(1);
  animation-name: mbsc-anim-sh-in;
}

.mbsc-anim-out .mbsc-anim-slidehorizontal {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-sh-out;
  animation-name: mbsc-anim-sh-out;
}

.mbsc-anim-in .mbsc-anim-slidevertical {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-sv-in;
  -webkit-transform: scale(1);
  animation-name: mbsc-anim-sv-in;
  transform: scale(1);
}

.mbsc-anim-out .mbsc-anim-slidevertical {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-sv-out;
  animation-name: mbsc-anim-sv-out;
}

.mbsc-anim-in .mbsc-anim-slidedown {
  -webkit-animation-name: mbsc-anim-sd-in;
  -webkit-transform: scale(1);
  animation-name: mbsc-anim-sd-in;
  transform: scale(1);
}

.mbsc-anim-out .mbsc-anim-slidedown {
  -webkit-animation-name: mbsc-anim-sd-out;
  -webkit-transform: translateY(-100%);
  animation-name: mbsc-anim-sd-out;
}

.mbsc-anim-in .mbsc-anim-slideup {
  -webkit-animation-name: mbsc-anim-su-in;
  -webkit-transform: scale(1);
  transform: scale(1);
  animation-name: mbsc-anim-su-in;
}

.mbsc-anim-out .mbsc-anim-slideup {
  -webkit-animation-name: mbsc-anim-su-out;
  -webkit-transform: translateY(100%);
  animation-name: mbsc-anim-su-out;
}

.mbsc-anim-in .mbsc-anim-fade {
  opacity: 1;
  -webkit-animation-name: mbsc-anim-f-in;
  animation-name: mbsc-anim-f-in;
}

.mbsc-anim-out .mbsc-anim-fade {
  opacity: 0;
  -webkit-animation-name: mbsc-anim-f-out;
  animation-name: mbsc-anim-f-out;
}

.mbsc-fr-pointer.mbsc-anim-in .mbsc-anim-slidedown {
  -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
  animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
}
.mbsc-fr-pointer.mbsc-anim-out .mbsc-anim-slidedown {
  -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
  animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
}
.mbsc-fr-pointer.mbsc-anim-in .mbsc-anim-slideup {
  -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
  animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
}
.mbsc-fr-pointer.mbsc-anim-out .mbsc-anim-slideup {
  -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
  animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
}

/* Fade in */
@keyframes mbsc-anim-f-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes mbsc-anim-f-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade out */
@keyframes mbsc-anim-f-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes mbsc-anim-f-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Pop in */
@keyframes mbsc-anim-p-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes mbsc-anim-p-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
/* Pop out */
@keyframes mbsc-anim-p-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@-webkit-keyframes mbsc-anim-p-out {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
/* Flip in */
@keyframes mbsc-anim-fl-in {
  from {
    opacity: 0;
    transform: rotateY(90deg);
  }
  to {
    opacity: 1;
    transform: rotateY(0);
  }
}
@-webkit-keyframes mbsc-anim-fl-in {
  from {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateY(0);
  }
}
/* Flip out */
@keyframes mbsc-anim-fl-out {
  from {
    opacity: 1;
    transform: rotateY(0deg);
  }
  to {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}
@-webkit-keyframes mbsc-anim-fl-out {
  from {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
}
/* Swing in */
@keyframes mbsc-anim-sw-in {
  from {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  to {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes mbsc-anim-sw-in {
  from {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
  }
}
/* Swing out */
@keyframes mbsc-anim-sw-out {
  from {
    opacity: 1;
    transform: rotateY(0deg);
  }
  to {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}
@-webkit-keyframes mbsc-anim-sw-out {
  from {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
  }
  to {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
  }
}
/* Slide horizontal in */
@keyframes mbsc-anim-sh-in {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes mbsc-anim-sh-in {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
/* Slide horizontal out */
@keyframes mbsc-anim-sh-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
@-webkit-keyframes mbsc-anim-sh-out {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
}
/* Slide vertical in */
@keyframes mbsc-anim-sv-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes mbsc-anim-sv-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
/* Slide vertical out */
@keyframes mbsc-anim-sv-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
@-webkit-keyframes mbsc-anim-sv-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}
/* Slide Down In */
@keyframes mbsc-anim-sd-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes mbsc-anim-sd-in {
  from {
    opacity: 1;
    -webkit-transform: translateY(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
/* Slide down out */
@keyframes mbsc-anim-sd-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes mbsc-anim-sd-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(-100%);
  }
}
/* Slide Up In */
@keyframes mbsc-anim-su-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes mbsc-anim-su-in {
  from {
    opacity: 1;
    -webkit-transform: translateY(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
/* Slide up out */
@keyframes mbsc-anim-su-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@-webkit-keyframes mbsc-anim-su-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(100%);
  }
}
@font-face {
  font-family: "icons_mobiscroll";
  src: url("~@mobiscroll/angular/dist/css/icons_mobiscroll.woff?eeozjk") format("woff"), url("~@mobiscroll/angular/dist/css/icons_mobiscroll.woff") format("woff"), url("~@mobiscroll/angular/dist/css/icons_mobiscroll.ttf?eeozjk") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mbsc-ic:before {
  font-family: "icons_mobiscroll";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Icons */
.mbsc-ic-aid::before {
  content: "";
}

.mbsc-ic-airplane::before {
  content: "";
}

.mbsc-ic-alarm2::before {
  content: "";
}

.mbsc-ic-arrow-down5::before {
  content: "";
}

.mbsc-ic-arrow-left5::before {
  content: "";
}

.mbsc-ic-arrow-left6::before {
  content: "";
}

.mbsc-ic-arrow-right5::before {
  content: "";
}

.mbsc-ic-arrow-right6::before {
  content: "";
}

.mbsc-ic-arrow-up5::before {
  content: "";
}

.mbsc-ic-attachment::before {
  content: "";
}

.mbsc-ic-bars::before {
  content: "";
}

.mbsc-ic-book::before {
  content: "";
}

.mbsc-ic-bubble::before {
  content: "";
}

.mbsc-ic-bubbles::before {
  content: "";
}

.mbsc-ic-bullhorn::before {
  content: "";
}

.mbsc-ic-calendar::before {
  content: "";
}

.mbsc-ic-camera::before {
  content: "";
}

.mbsc-ic-cart::before {
  content: "";
}

.mbsc-ic-checkmark::before {
  content: "";
}

.mbsc-ic-clock::before {
  content: "";
}

.mbsc-ic-close::before {
  content: "";
}

.mbsc-ic-cloud-download::before {
  content: "";
}

.mbsc-ic-cloud-upload::before {
  content: "";
}

.mbsc-ic-cogs::before {
  content: "";
}

.mbsc-ic-connection::before {
  content: "";
}

.mbsc-ic-copy2::before {
  content: "";
}

.mbsc-ic-copy3::before {
  content: "";
}

.mbsc-ic-credit::before {
  content: "";
}

.mbsc-ic-disk::before {
  content: "";
}

.mbsc-ic-download::before {
  content: "";
}

.mbsc-ic-drawer::before {
  content: "";
}

.mbsc-ic-droplet::before {
  content: "";
}

.mbsc-ic-earth::before {
  content: "";
}

.mbsc-ic-eye-blocked::before {
  content: "";
}

.mbsc-ic-eye::before {
  content: "";
}

.mbsc-ic-fa-globe::before {
  content: "";
}

.mbsc-ic-fa-leaf::before {
  content: "";
}

.mbsc-ic-fa-mail-reply::before {
  content: "";
}

.mbsc-ic-fa-retweet::before {
  content: "";
}

.mbsc-ic-fa-rotate-left::before {
  content: "";
}

.mbsc-ic-file4::before {
  content: "";
}

.mbsc-ic-film::before {
  content: "";
}

.mbsc-ic-flag::before {
  content: "";
}

.mbsc-ic-folder::before {
  content: "";
}

.mbsc-ic-forward::before {
  content: "";
}

.mbsc-ic-foundation-mail::before {
  content: "";
}

.mbsc-ic-foundation-minus-circle::before {
  content: "";
}

.mbsc-ic-globe::before {
  content: "";
}

.mbsc-ic-heart::before {
  content: "";
}

.mbsc-ic-history::before {
  content: "";
}

.mbsc-ic-home::before {
  content: "";
}

.mbsc-ic-image2::before {
  content: "";
}

.mbsc-ic-ion-android-system-windows::before {
  content: "";
}

.mbsc-ic-ion-bluetooth::before {
  content: "";
}

.mbsc-ic-ion-navigate::before {
  content: "";
}

.mbsc-ic-key2::before {
  content: "";
}

.mbsc-ic-library::before {
  content: "";
}

.mbsc-ic-line-settings::before {
  content: "";
}

.mbsc-ic-link::before {
  content: "";
}

.mbsc-ic-location::before {
  content: "";
}

.mbsc-ic-lock2::before {
  content: "";
}

.mbsc-ic-loop2::before {
  content: "";
}

.mbsc-ic-map::before {
  content: "";
}

.mbsc-ic-material-arrow-back::before {
  content: "";
}

.mbsc-ic-material-brightness-medium::before {
  content: "";
}

.mbsc-ic-material-check::before {
  content: "";
}

.mbsc-ic-material-crop::before {
  content: "";
}

.mbsc-ic-material-equalizer::before {
  content: "";
}

.mbsc-ic-material-filter::before {
  content: "";
}

.mbsc-ic-material-iso::before {
  content: "";
}

.mbsc-ic-material-palette::before {
  content: "";
}

.mbsc-ic-material-pause::before {
  content: "";
}

.mbsc-ic-material-people::before {
  content: "";
}

.mbsc-ic-material-photo-size-select-large::before {
  content: "";
}

.mbsc-ic-material-play-arrow::before {
  content: "";
}

.mbsc-ic-material-repeat::before {
  content: "";
}

.mbsc-ic-material-rotate-right::before {
  content: "";
}

.mbsc-ic-material-shuffle::before {
  content: "";
}

.mbsc-ic-material-skip-next::before {
  content: "";
}

.mbsc-ic-material-skip-previous::before {
  content: "";
}

.mbsc-ic-material-stop::before {
  content: "";
}

.mbsc-ic-material-texture::before {
  content: "";
}

.mbsc-ic-material-wb-auto::before {
  content: "";
}

.mbsc-ic-meteo-cloud2::before {
  content: "";
}

.mbsc-ic-meteo-cloud3::before {
  content: "";
}

.mbsc-ic-meteo-cloudy::before {
  content: "";
}

.mbsc-ic-meteo-sun::before {
  content: "";
}

.mbsc-ic-minus::before {
  content: "";
}

.mbsc-ic-mobile::before {
  content: "";
}

.mbsc-ic-music::before {
  content: "";
}

.mbsc-ic-neutral::before {
  content: "";
}

.mbsc-ic-newspaper::before {
  content: "";
}

.mbsc-ic-office::before {
  content: "";
}

.mbsc-ic-pencil::before {
  content: "";
}

.mbsc-ic-phone::before {
  content: "";
}

.mbsc-ic-play::before {
  content: "";
}

.mbsc-ic-plus::before {
  content: "";
}

.mbsc-ic-redo2::before {
  content: "";
}

.mbsc-ic-remove::before {
  content: "";
}

.mbsc-ic-reply::before {
  content: "";
}

.mbsc-ic-sad::before {
  content: "";
}

.mbsc-ic-sad2::before {
  content: "";
}

.mbsc-ic-share::before {
  content: "";
}

.mbsc-ic-smiley::before {
  content: "";
}

.mbsc-ic-smiley2::before {
  content: "";
}

.mbsc-ic-stack::before {
  content: "";
}

.mbsc-ic-star3::before {
  content: "";
}

.mbsc-ic-stopwatch::before {
  content: "";
}

.mbsc-ic-support::before {
  content: "";
}

.mbsc-ic-tag::before {
  content: "";
}

.mbsc-ic-thumbs-up::before {
  content: "";
}

.mbsc-ic-thumbs-up2::before {
  content: "";
}

.mbsc-ic-undo2::before {
  content: "";
}

.mbsc-ic-unlocked::before {
  content: "";
}

.mbsc-ic-upload::before {
  content: "";
}

.mbsc-ic-user4::before {
  content: "";
}

.mbsc-ic-volume-high::before {
  content: "";
}

.mbsc-ic-volume-medium::before {
  content: "";
}

.mbsc-ic-volume-mute2::before {
  content: "";
}

.mbsc-fr-w,
.mbsc-fr-overlay {
  -webkit-transform: translateZ(0);
}

.mbsc-fr {
  pointer-events: none;
  z-index: 99998;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

.mbsc-fr-focus {
  outline: 0;
}

.mbsc-fr-lock-ctx {
  position: relative;
}

.mbsc-fr-lock.mbsc-fr-lock-ios {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}

.mbsc-fr-pos {
  visibility: hidden;
}

.mbsc-fr-scroll {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.mbsc-fr-popup {
  max-width: 98%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  font-size: 12px;
  text-shadow: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.mbsc-rtl {
  direction: rtl;
}

/* Box sizing */
.mbsc-fr-popup,
.mbsc-fr-btn-cont,
.mbsc-fr-arr {
  box-sizing: border-box;
}

.mbsc-fr .mbsc-fr-w {
  box-sizing: content-box;
}

.mbsc-fr-w {
  min-width: 256px;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  font-family: arial, verdana, sans-serif;
}

/* Modal overlay */
.mbsc-fr,
.mbsc-fr-persp,
.mbsc-fr-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mbsc-fr-lock .mbsc-fr-persp {
  -ms-touch-action: none;
  touch-action: none;
}

.mbsc-fr-lock-ctx > .mbsc-fr,
.mbsc-fr-lock-ctx .mbsc-fr-persp,
.mbsc-fr-lock-ctx .mbsc-fr-overlay {
  position: absolute;
}

.mbsc-fr-persp {
  pointer-events: auto;
  overflow: hidden;
}

.mbsc-fr-overlay {
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

/* Liquid mode */
.mbsc-fr-liq .mbsc-fr-popup {
  max-width: 100%;
}

/* Top/Bottom mode */
.mbsc-fr-top .mbsc-fr-popup,
.mbsc-fr-bottom .mbsc-fr-popup {
  width: 100%;
  max-width: 100%;
}

.mbsc-fr-top .mbsc-fr-w,
.mbsc-fr-bottom .mbsc-fr-w {
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
}

.mbsc-fr-bottom .mbsc-fr-w {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.mbsc-fr-top .mbsc-fr-popup {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

/* Inline mode */
.mbsc-fr-inline {
  position: relative;
  pointer-events: auto;
  z-index: 0;
}

.mbsc-fr-inline .mbsc-fr-popup {
  position: static;
  max-width: 100%;
}

/* Bubble mode */
.mbsc-fr-bubble,
.mbsc-fr-bubble .mbsc-fr-persp {
  position: absolute;
}

.mbsc-fr-bubble .mbsc-fr-arr-w {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.mbsc-fr-bubble-top .mbsc-fr-arr-w {
  top: 100%;
}

.mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
  bottom: 100%;
}

.mbsc-fr-bubble .mbsc-fr-arr-i {
  margin: 0 1.75em;
  position: relative;
  direction: ltr;
}

.mbsc-fr-bubble .mbsc-fr-arr {
  display: block;
}

.mbsc-fr-arr {
  display: none;
  position: relative;
  left: 0;
  width: 2em;
  height: 2em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: -1em;
}

.mbsc-fr-bubble-bottom .mbsc-fr-arr {
  top: 1.333334em;
}

.mbsc-fr-bubble-top .mbsc-fr-arr {
  top: -1.333334em;
}

.mbsc-fr-hdn {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

/* Header */
.mbsc-fr-hdr {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

/* Buttons */
.mbsc-fr-btn {
  overflow: hidden;
  display: block;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}

.mbsc-fr-btn-e {
  cursor: pointer;
}

.mbsc-fr-btn.mbsc-disabled {
  cursor: not-allowed;
}

/* Button container */
.mbsc-fr-btn-cont {
  display: table;
  width: 100%;
  text-align: center;
  white-space: normal;
}

.mbsc-fr-btn-cont .mbsc-disabled {
  opacity: 0.3;
}

/* Button wrapper */
.mbsc-fr-btn-w {
  vertical-align: top;
  display: table-cell;
  position: relative;
  z-index: 5;
}

.mbsc-fr-btn-w .mbsc-fr-btn:before {
  padding: 0.375em;
}

/* Desktop view */
.mbsc-fr-pointer {
  /* Embedded components */
}
.mbsc-fr-pointer.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
  box-shadow: none;
  border-radius: 0;
}
.mbsc-fr-pointer .mbsc-ltr .mbsc-fr-w,
.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
  text-align: left;
}
.mbsc-fr-pointer .mbsc-rtl .mbsc-fr-w,
.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
  text-align: right;
}
.mbsc-fr-pointer.mbsc-fr-top .mbsc-fr-w, .mbsc-fr-pointer.mbsc-fr-bottom .mbsc-fr-w {
  pointer-events: auto;
  display: inline-block;
  margin-top: 3em;
  margin-bottom: 3em;
  max-width: 98%;
}
.mbsc-fr-pointer.mbsc-fr-top .mbsc-fr-popup, .mbsc-fr-pointer.mbsc-fr-bottom .mbsc-fr-popup {
  text-align: center;
  pointer-events: none;
}
.mbsc-fr-pointer.mbsc-fr-bubble .mbsc-fr-arr-w {
  display: none;
}
.mbsc-fr-pointer .mbsc-sel-empty {
  text-align: center;
}

.mbsc-mobiscroll {
  /* Display modes */
}
.mbsc-mobiscroll .mbsc-fr-w {
  min-width: 16em;
  font-size: 16px;
}
.mbsc-mobiscroll .mbsc-fr-hdr {
  padding: 0 0.6666em;
  padding-top: 0.6666em;
  font-size: 0.75em;
  text-transform: uppercase;
  min-height: 2em;
  line-height: 2em;
}
.mbsc-mobiscroll .mbsc-fr-btn-cont {
  display: block;
  overflow: hidden;
  text-align: right;
  padding: 0 0.5em 0.5em 0.5em;
}
.mbsc-mobiscroll .mbsc-ltr .mbsc-fr-btn-cont {
  text-align: right;
}
.mbsc-mobiscroll .mbsc-rtl .mbsc-fr-btn-cont {
  text-align: left;
}
.mbsc-mobiscroll .mbsc-fr-btn-w {
  display: inline-block;
}
.mbsc-mobiscroll .mbsc-fr-btn {
  height: 2.5em;
  line-height: 2.5em;
  padding: 0 1em;
  text-transform: uppercase;
}
.mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w, .mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
  border-radius: 0.25em;
}
.mbsc-mobiscroll.mbsc-fr-no-overlay .mbsc-fr-arr {
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
}
.mbsc-mobiscroll.mbsc-fr-no-overlay .mbsc-fr-w {
  box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.3);
}
.mbsc-mobiscroll.mbsc-fr-no-overlay.mbsc-fr-bubble .mbsc-fr-w {
  border-radius: 0.25em;
}

.mbsc-mobiscroll .mbsc-fr-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.mbsc-mobiscroll .mbsc-fr-w {
  background: #f7f7f7;
  color: #454545;
}
.mbsc-mobiscroll .mbsc-fr-hdr,
.mbsc-mobiscroll .mbsc-fr-btn {
  color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: rgba(78, 204, 196, 0.3);
}
.mbsc-mobiscroll .mbsc-fr-arr {
  background: #f7f7f7;
}

.mbsc-sc-whl-o,
.mbsc-sc-btn {
  /* Prevent flickering on animation */
  -webkit-transform: translateZ(0);
}

/* Force content box */
.mbsc-sc .mbsc-sc-whl-c,
.mbsc-sc .mbsc-sc-whl-l,
.mbsc-sc .mbsc-sc-whl {
  box-sizing: content-box;
}

/* Force border box */
.mbsc-sc-whl-gr-c,
.mbsc-sc-itm {
  box-sizing: border-box;
}

.mbsc-sc-whl-gr-c {
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
.mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
.mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
.mbsc-fr-liq .mbsc-sc-whl-gr-c {
  display: block;
}

.mbsc-sc-whl-gr {
  margin: 0 auto;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.mbsc-sc-whl-w {
  max-width: 100%;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
}

.mbsc-fr-pointer .mbsc-sc-whl-w,
.mbsc-fr-liq .mbsc-sc-whl-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
}

.mbsc-sc-whl-o {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  display: none;
}

.mbsc-sc-whl-l {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
}

.mbsc-sc-whl-w .mbsc-sc-whl-l {
  display: block;
}

.mbsc-sc-whl {
  overflow: hidden;
  /* Forces IE to respect overflow hidden while animating */
  /* Looks like this is not needed, also, it brakes rendering on Samsung S5 Mini */
  /* border-radius: 1px; */
  /* Fixes Firefox rendering issues */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: -1px 0;
}

.mbsc-sc-whl-c {
  position: relative;
  z-index: 1;
  top: 50%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.mbsc-sc-whl-sc {
  position: relative;
}

.mbsc-sc-itm {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mbsc-sc-itm-inv,
.mbsc-sc-itm-inv-h {
  opacity: 0.3;
}

.mbsc-sc-lbl {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  display: none;
}

.mbsc-sc-lbl-v .mbsc-sc-lbl {
  display: block;
}

.mbsc-sc-btn {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  transition: opacity 0.2s linear;
}

.mbsc-sc-btn:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.mbsc-sc-whl-a .mbsc-sc-btn {
  opacity: 0;
}

.mbsc-sc-btn-plus {
  bottom: 0;
}

.mbsc-sc-btn-minus {
  top: 0;
}

/* 3D */
.mbsc-sc-whl-gr-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.mbsc-sc-whl-gr-3d .mbsc-sc-whl {
  /* For iOS to respect z-index */
  overflow: visible;
}

.mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  overflow: hidden;
}

.mbsc-sc-whl-gr-3d .mbsc-sc-whl-w,
.mbsc-sc-whl-gr-3d .mbsc-sc-whl {
  /* For Edge and Firefox */
  transform-style: preserve-3d;
}

.mbsc-sc-whl-3d {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.mbsc-sc-itm-3d {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mbsc-sc-itm-del {
  display: none;
}

/* Multiline */
.mbsc-sc-itm-ml {
  width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

/* Multiple */
.mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  text-align: center;
}

/* Desktop view */
.mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr-c,
.mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr {
  padding: 0;
}
.mbsc-fr-pointer .mbsc-sc-whl-gr-c:first-child:last-child {
  display: block;
}

.mbsc-sc-bar-c {
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  transform: translateZ(0);
  transition: opacity 0.2s;
}

.mbsc-sc-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 100%;
}

.mbsc-sc-bar:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
.mbsc-sc-whl-anim .mbsc-sc-bar-c {
  opacity: 1;
}

.mbsc-mobiscroll {
  /* Multiple select */
  /* Desktop view */
}
.mbsc-mobiscroll .mbsc-sc-whl-gr {
  padding: 0.5em 0.25em;
}
.mbsc-mobiscroll .mbsc-sc-whl-w {
  margin: 0 0.25em;
}
.mbsc-mobiscroll .mbsc-sc-lbl-v .mbsc-sc-whl-w {
  margin-top: 1.875em;
}
.mbsc-mobiscroll .mbsc-sc-lbl {
  font-size: 0.75em;
  line-height: 2.5em;
  text-transform: uppercase;
}
.mbsc-mobiscroll .mbsc-sc-cp .mbsc-sc-whl-w {
  padding: 2em 0;
}
.mbsc-mobiscroll .mbsc-sc-btn {
  height: 2em;
  line-height: 2em;
}
.mbsc-mobiscroll .mbsc-sc-btn:before {
  font-size: 1.5em;
}
.mbsc-mobiscroll .mbsc-sc-itm {
  padding: 0 0.25em;
  font-size: 1.375em;
}
.mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
.mbsc-mobiscroll .mbsc-sc-itm:focus {
  outline: 0;
}
.mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm {
  padding: 0 1.818181em;
}
.mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  font-size: 1.818181em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-lbl {
  padding-left: 1.666667em;
  padding-right: 1.666667em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-w {
  margin-left: 0;
  margin-right: 0;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm {
  font-size: 1em;
  padding: 0 1.25em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
  padding: 0 2.5em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  font-size: 2em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  left: 0.125em;
}
.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  right: 0.125em;
}

.mbsc-mobiscroll {
  /* Multiple select */
}
.mbsc-mobiscroll .mbsc-sc-lbl {
  color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-sc-whl-l {
  border-top: 1px solid #4eccc4;
  border-bottom: 1px solid #4eccc4;
}
.mbsc-mobiscroll .mbsc-sc-btn {
  color: #4eccc4;
  background: #f7f7f7;
}
.mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
.mbsc-mobiscroll .mbsc-sc-itm:focus {
  background: rgba(69, 69, 69, 0.1);
}
.mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-btn:hover:before, .mbsc-mobiscroll.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
.mbsc-mobiscroll .mbsc-sc-btn.mbsc-active:before {
  background: rgba(78, 204, 196, 0.3);
}
.mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  color: #4eccc4;
}

/* Box sizing */
.mbsc-cal-slide,
.mbsc-cal-day,
.mbsc-cal-day-i {
  box-sizing: border-box;
}

.mbsc-cal .mbsc-cal-day-date {
  text-align: center;
  box-sizing: content-box;
}

/* Flex layout */
.mbsc-cal-hdr,
.mbsc-cal-btn-w,
.mbsc-cal-days-c,
.mbsc-cal-days,
.mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mbsc-cal-btn-w,
.mbsc-cal-month,
.mbsc-cal-year,
.mbsc-cal-days,
.mbsc-cal-days > div {
  -webkit-box-flex: 1;
  -webkit-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
}

.mbsc-cal-c {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

/* Header */
.mbsc-cal-btn-w {
  width: 50%;
}

.mbsc-cal-month,
.mbsc-cal-year {
  width: 1%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.mbsc-cal-btn.mbsc-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/* Week days */
.mbsc-cal-days {
  line-height: 1.875em;
  text-align: center;
}

.mbsc-cal-days > div {
  width: 14.285715%;
}

.mbsc-cal-body {
  position: relative;
}

.mbsc-cal-scroll-c {
  overflow: hidden;
  height: 100%;
}

.mbsc-cal-scroll {
  width: 100%;
  height: 100%;
}

.mbsc-cal-slide {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mbsc-cal-slide:first-child {
  position: relative;
}

.mbsc-cal-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  -webkit-transform: translateZ(0);
}

.mbsc-cal-row {
  display: table-row;
}

.mbsc-cal-cell {
  display: table-cell;
  vertical-align: middle;
}
.mbsc-cal-cell:focus {
  outline: 0;
}

.mbsc-cal-picker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.9375em 0;
  transition: all 0.2s ease-in-out;
  will-change: opacity;
}
.mbsc-cal-picker .mbsc-cal-cell {
  width: 33.333333%;
  height: 25%;
  cursor: pointer;
}

.mbsc-cal-h {
  visibility: hidden;
  opacity: 0;
}

.mbsc-cal-day {
  max-width: 14.285715%;
  height: 16.666667%;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.mbsc-cal-day-i {
  position: relative;
  height: 100%;
}

.mbsc-cal-day-markup {
  overflow: hidden;
}

.mbsc-cal-day-diff .mbsc-cal-day-date {
  opacity: 0.5;
}

.mbsc-cal-week-view .mbsc-cal-day-date,
.mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
  opacity: 1;
}

.mbsc-cal-cell.mbsc-disabled {
  cursor: not-allowed;
}
.mbsc-cal-cell.mbsc-disabled .mbsc-cal-cell-txt {
  opacity: 0.2;
}

/* Hide outer days */
.mbsc-cal-hide-diff .mbsc-cal-day-diff {
  cursor: default;
}
.mbsc-cal-hide-diff .mbsc-cal-day-diff .mbsc-cal-day-i {
  visibility: hidden;
}

/* Week numbers */
.mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
  margin-left: 1.625em;
}

.mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
  margin-right: 1.625em;
}

.mbsc-cal-week-nr {
  width: 1.625em;
  text-align: center;
}

/* Multiple month */
.mbsc-cal-multi .mbsc-cal-hdr {
  display: block;
}
.mbsc-cal-multi .mbsc-cal-btn-w {
  width: auto;
}
.mbsc-cal-multi .mbsc-cal-days,
.mbsc-cal-multi .mbsc-cal-slide {
  padding: 0 0.5em;
}

/* Liquid mode */
.mbsc-fr-liq .mbsc-cal-c {
  display: block;
}

/* Full screen mode */
.mbsc-calendar.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-overlay {
  display: none;
}
.mbsc-calendar.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-w {
  border-radius: 0;
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/* Tabs */
.mbsc-cal-tabs-c {
  display: none;
}

.mbsc-cal-tabs {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mbsc-cal-tab {
  position: relative;
  width: 33.33%;
  display: table-cell;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}

.mbsc-cal-tabbed {
  /* During positioning */
}
.mbsc-cal-tabbed .mbsc-fr-c {
  position: relative;
}
.mbsc-cal-tabbed .mbsc-w-p {
  transition: opacity 0.2s ease-in-out;
}
.mbsc-cal-tabbed .mbsc-sc-whl-gr-c {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  max-width: none;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-box-align: center;
  align-items: center;
}
.mbsc-cal-tabbed .mbsc-cal-c,
.mbsc-cal-tabbed .mbsc-cal-tabs-c {
  display: block;
}
.mbsc-cal-tabbed .mbsc-fr-pos .mbsc-sc-whl-gr-c {
  width: auto;
}

/* Marks */
.mbsc-cal-has-marks .mbsc-cal-day {
  padding-bottom: 0.25em;
}

.mbsc-cal-marks {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.375em;
  margin-top: -0.125em;
  overflow: hidden;
  white-space: nowrap;
}

.mbsc-cal-mark {
  display: inline-block;
  vertical-align: top;
  width: 0.25em;
  height: 0.25em;
  margin: 0.0625em;
  border-radius: 0.25em;
}

/* Text & icons */
.mbsc-cal-txt-w {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.mbsc-ltr .mbsc-cal-txt {
  text-align: left;
}

.mbsc-rtl .mbsc-cal-txt {
  text-align: right;
}

.mbsc-cal-txt,
.mbsc-cal-txt-ph,
.mbsc-cal-txt-more {
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  overflow: hidden;
  padding: 0 0.3em;
  font-size: 0.625em;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0.1em 0.2em 0.1em;
}

.mbsc-cal-day .mbsc-cal-txt.mbsc-cal-txt-only {
  color: inherit;
  text-align: center;
}

.mbsc-no-touch .mbsc-cal-txt:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s;
}

.mbsc-no-touch .mbsc-cal-txt-only:before {
  display: none;
}

.mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}

.mbsc-no-touch .mbsc-cal-txt-more:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mbsc-cal-txt-more {
  text-align: left;
  transition: background-color 0.2s;
}

.mbsc-cal .mbsc-cal-btn {
  position: relative;
  z-index: 1;
}

.mbsc-mobiscroll {
  /* Week numbers */
  /* Tabs */
}
.mbsc-mobiscroll .mbsc-cal-c {
  padding: 0.5em;
}
.mbsc-mobiscroll .mbsc-cal-btn-w {
  font-size: 1.125em;
  line-height: 2.222223em;
}
.mbsc-mobiscroll .mbsc-cal .mbsc-cal-btn {
  width: 2.666667em;
  height: 2.666667em;
  line-height: 2.666667em;
  padding: 0;
  border-radius: 2em;
  margin: -0.222222em;
}
.mbsc-mobiscroll .mbsc-cal-picker {
  border-top: 1px solid transparent;
}
.mbsc-mobiscroll .mbsc-cal-picker .mbsc-cal-cell-i {
  display: inline-block;
  height: 2em;
  padding: 0 1em;
  line-height: 2em;
  border-radius: 2em;
  white-space: nowrap;
}
.mbsc-mobiscroll .mbsc-cal-days > div {
  font-size: 0.6875em;
}
.mbsc-mobiscroll .mbsc-cal-day-date {
  display: inline-block;
  width: 2.153847em;
  height: 2.153847em;
  margin: 0.307692em 0;
  line-height: 2.153847em;
  font-size: 0.8125em;
  border: 2px solid transparent;
  border-radius: 2em;
}
.mbsc-mobiscroll .mbsc-cal-week-nr {
  width: 2.363637em;
  font-size: 0.6875em;
}
.mbsc-mobiscroll .mbsc-cal-tabs-c {
  line-height: 1.875em;
  padding: 0.5em 0.5em 0 0.5em;
  text-transform: uppercase;
}
.mbsc-mobiscroll .mbsc-cal-tab {
  font-size: 0.7em;
  padding: 0 1.2em;
}

.mbsc-mobiscroll {
  /* Hover, focus */
  /* Selected */
  /* Week numbers */
  /* Marks */
}
.mbsc-mobiscroll .mbsc-cal-picker {
  background: #f7f7f7;
}
.mbsc-mobiscroll .mbsc-cal-days > div {
  border-bottom: 1px solid #4eccc4;
  color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-cal-today {
  color: #4eccc4;
}
.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
.mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
  background: rgba(78, 204, 196, 0.3);
}
.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
  background: #4eccc4;
  color: #f7f7f7;
}
.mbsc-mobiscroll .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
  border-color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-cal-week-nr {
  color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-cal-tab {
  border: 1px solid #4eccc4;
  color: #454545;
}
.mbsc-mobiscroll .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
  background: #4eccc4;
  color: #f7f7f7;
}
.mbsc-mobiscroll .mbsc-cal-mark {
  background: #454545;
}
.mbsc-mobiscroll .mbsc-cal-txt {
  color: #f7f7f7;
  background: #454545;
}
.mbsc-mobiscroll .mbsc-cal-icons {
  color: #454545;
}

@keyframes autofill {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
.mbsc-input {
  display: block;
  margin: 0;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mbsc-input.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-control-w.mbsc-rtl {
  width: auto;
  font-size: 1em;
  line-height: normal;
  max-width: none;
  position: relative;
}

.mbsc-input.mbsc-label-floating.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-label-floating.mbsc-control-w.mbsc-rtl,
.mbsc-input.mbsc-label-stacked.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-label-stacked.mbsc-control-w.mbsc-rtl {
  top: 0;
  left: 0;
  right: 0;
  transform: none;
}

.mbsc-input .mbsc-control:-webkit-autofill {
  animation-name: autofill;
}

.mbsc-input input,
.mbsc-input select,
.mbsc-input textarea {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mbsc-input-wrap,
.mbsc-input input,
.mbsc-input textarea {
  box-sizing: border-box;
}

.mbsc-input input,
.mbsc-input select,
.mbsc-input textarea {
  display: block;
  width: 100%;
  font-size: 1em;
}

.mbsc-input input:focus,
.mbsc-input select:focus,
.mbsc-input textarea:focus {
  outline: 0;
}

.mbsc-input-wrap {
  position: relative;
  display: block;
}

/* Textbox, textarea, select */
.mbsc-input .mbsc-label {
  font-size: 0.875em;
  white-space: nowrap;
}

.mbsc-input-ic {
  position: absolute;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
}

.mbsc-input-toggle {
  cursor: pointer;
}

.mbsc-ic-right .mbsc-input-ic {
  right: 0.5em;
}

.mbsc-ic-left .mbsc-input-ic {
  left: 0.5em;
}

.mbsc-ic-right input {
  padding-right: 2.4em;
}

.mbsc-ic-left input {
  padding-left: 2.4em;
}

/* Inline labels */
.mbsc-label-inline.mbsc-input.mbsc-control-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.mbsc-label-inline.mbsc-input.mbsc-control-w .mbsc-input-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
}
.mbsc-label-inline.mbsc-input.mbsc-control-w .mbsc-label {
  position: static;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1em;
  width: 30%;
  max-width: 12.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: content-box;
}

/* Floating labels */
.mbsc-label-floating.mbsc-input .mbsc-label {
  pointer-events: none;
  transition: transform 0.2s;
}
.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
}
.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-webkit-input-placeholder {
  opacity: 0;
}
.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-moz-placeholder {
  opacity: 0;
}
.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-moz-placeholder {
  opacity: 0;
}
.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-ms-input-placeholder {
  opacity: 0;
}
.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label ~ .mbsc-input-wrap {
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
}
.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.2s;
}
.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.2s;
}
.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.2s;
}
.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.2s;
}

.mbsc-ltr.mbsc-label-floating.mbsc-input .mbsc-label {
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.mbsc-rtl.mbsc-label-floating.mbsc-input .mbsc-label {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

/* file type */
.mbsc-input-wrap .mbsc-control[type=file] {
  position: absolute;
  left: 0;
  opacity: 0;
}

.mbsc-mobiscroll {
  /* Textbox */
  /* Input */
  /* Textarea */
  /* Select */
  /* Select inline */
  /* LTR left icon */
  /* LTR right icon */
  /* RTL left icon */
  /* RTL right icon */
  /* Inline labels */
  /* Floating labels */
  /* Box Input */
  /* Outline Input */
}
.mbsc-mobiscroll.mbsc-input input, .mbsc-mobiscroll.mbsc-input textarea, .mbsc-mobiscroll.mbsc-color-input {
  height: 2.125em;
  padding: 0 0 1px 0;
  background: transparent;
  font-size: 1em;
}
.mbsc-mobiscroll.mbsc-input input:disabled, .mbsc-mobiscroll.mbsc-input textarea:disabled, .mbsc-mobiscroll.mbsc-input input:disabled ~ .mbsc-color-input, .mbsc-mobiscroll.mbsc-input .mbsc-control:disabled ~ input, .mbsc-mobiscroll.mbsc-input .mbsc-control:disabled ~ .mbsc-select-ic {
  opacity: 0.4;
}
.mbsc-mobiscroll.mbsc-input {
  margin: 1.5em 1em;
}
.mbsc-mobiscroll.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0.875em;
}
.mbsc-mobiscroll.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
  top: 0.9375em;
}
.mbsc-mobiscroll .mbsc-input-ic {
  top: 0.0625em;
  margin: -2px 0 0 0;
}
.mbsc-mobiscroll.mbsc-input textarea {
  padding-top: 0.25em;
}
.mbsc-mobiscroll .mbsc-select-ic {
  position: absolute;
  display: block;
  height: 1.25em;
  width: 1.25em;
  top: 0.5625em;
  text-align: center;
}
.mbsc-mobiscroll.mbsc-ltr .mbsc-select-ic {
  left: auto;
  right: 0.6875em;
}
.mbsc-mobiscroll.mbsc-rtl .mbsc-select-ic {
  right: auto;
  left: 0.6875em;
}
.mbsc-mobiscroll .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 1.4375em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-select input {
  padding-right: 1.25em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-select.mbsc-ic-right input {
  padding-right: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  left: auto;
  right: 0;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  right: 1.75em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-select input {
  padding-left: 1.25em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-select.mbsc-ic-right input {
  padding-left: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  right: auto;
  left: 0;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  left: 1.75em;
}
.mbsc-mobiscroll .mbsc-select-inline .mbsc-select-ic {
  display: none;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-left textarea {
  padding-left: 1.625em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-left .mbsc-left-ic {
  right: auto;
  left: -0.375em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-left .mbsc-label,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-left .mbsc-err-msg {
  left: 2.166667em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-right textarea {
  padding-right: 1.625em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input.mbsc-ic-right .mbsc-right-ic {
  left: auto;
  right: -0.375em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-left textarea {
  padding-right: 1.625em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-left .mbsc-left-ic {
  left: auto;
  right: -0.375em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-left .mbsc-label,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-left .mbsc-err-msg {
  right: 2.166667em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-right textarea {
  padding-left: 1.625em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input.mbsc-ic-right .mbsc-right-ic {
  right: auto;
  left: -0.375em;
}
.mbsc-mobiscroll.mbsc-input .mbsc-label {
  position: absolute;
  top: 0;
  font-size: 0.75em;
}
.mbsc-mobiscroll.mbsc-label-inline.mbsc-input .mbsc-label {
  line-height: 2em;
}
.mbsc-mobiscroll.mbsc-label-inline.mbsc-input .mbsc-label + .mbsc-input-wrap {
  padding-top: 0;
}
.mbsc-mobiscroll.mbsc-label-inline.mbsc-input .mbsc-label + .mbsc-input-wrap .mbsc-input-ic {
  top: 0.0625em;
}
.mbsc-mobiscroll.mbsc-label-inline.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 0.5625em;
}
.mbsc-mobiscroll.mbsc-label-floating.mbsc-input .mbsc-label {
  font-size: 1em;
  line-height: 2em;
  top: 0.875em;
}
.mbsc-mobiscroll.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
  -webkit-transform: translateY(-1.125em) scale(0.75);
  transform: translateY(-1.125em) scale(0.75);
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  left: 1.625em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  right: 1.625em;
}
.mbsc-mobiscroll.mbsc-input-box {
  /* Inline labels */
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-input-wrap {
  border-radius: 4px 4px 0 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input input, .mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-color-input {
  height: 2.25em;
  padding: 0.0625em 1em;
  border-radius: 4px 4px 0 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input textarea {
  padding: 0.375em 1em 0.0625em 1em;
  height: 2.25em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap input,
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-color-input {
  padding-top: 1.25em;
  height: 3.5em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap textarea:focus {
  padding-bottom: 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
  top: 0.875em;
}
.mbsc-mobiscroll.mbsc-input-box .mbsc-input-ic {
  top: 0.25em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-label {
  padding: 0.666667em 0;
  z-index: 1;
  line-height: normal;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 1.625em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap textarea {
  height: 1.866667em;
  padding-top: 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  top: 0.625em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 1.375em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline .mbsc-label {
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  line-height: 2em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap input,
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-color-input {
  height: 2.25em;
  padding-top: 0.0625em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
  top: 0.1875em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0.375em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-inline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 0.625em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-floating .mbsc-label {
  top: 0.125em;
  line-height: 2em;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-label-floating.mbsc-label-floating-active .mbsc-label {
  line-height: normal;
  -webkit-transform: translateY(-0.125em) scale(0.75);
  transform: translateY(-0.125em) scale(0.75);
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box {
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box .mbsc-err-msg {
  left: 1.333334em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box .mbsc-label {
  left: 1.333334em;
  right: auto;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
  left: 4em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-left .mbsc-label {
  left: 4em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
  left: 0.5em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-left textarea {
  padding-left: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-right textarea {
  padding-right: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
  right: 0.5em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  right: 2em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-select input {
  padding-right: 2em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  right: 0.6875em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-select.mbsc-ic-right input {
  padding-right: 4em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  right: 2.75em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
  left: 1em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  left: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box {
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box .mbsc-err-msg {
  right: 1.333334em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box .mbsc-label {
  right: 1.333334em;
  left: auto;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
  right: 4em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-left .mbsc-label {
  right: 4em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
  right: 0.5em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-left textarea {
  padding-right: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-right textarea {
  padding-left: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
  left: 0.5em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  left: 2em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-select input {
  padding-left: 2em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  left: 0.6875em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-select.mbsc-ic-right input {
  padding-left: 4em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  left: 2.75em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
  right: 1em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  right: 3em;
}
.mbsc-mobiscroll.mbsc-input-outline {
  /* Inline labels */
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input input, .mbsc-mobiscroll.mbsc-input-outline.mbsc-input textarea, .mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-color-input {
  border-radius: 4px;
  height: 3.375em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input input, .mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-color-input {
  padding: 0.0625em 1em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input textarea {
  padding: 0.875em 1em 0.4375em 1em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-label {
  top: -0.166667em;
  z-index: 1;
  padding: 0 0.333334em;
}
.mbsc-mobiscroll.mbsc-input-outline .mbsc-input-ic {
  top: 0.8125em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0.25em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
  top: 1.0625em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input input:focus, .mbsc-mobiscroll.mbsc-input-outline.mbsc-input select:focus ~ input, .mbsc-mobiscroll.mbsc-input-outline.mbsc-err input {
  padding-top: 0;
  padding-bottom: 0;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-err input, .mbsc-mobiscroll.mbsc-input-outline.mbsc-err textarea {
  border-color: #de3226;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  top: 1.3125em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 1.5625em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-inline .mbsc-label {
  line-height: 3.375em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
  padding-top: 0;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
  top: 0.8125em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-inline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
  top: 1.3125em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
  top: 0.9375em;
  padding: 0 0.25em;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
  -webkit-transform: translateY(-1.375em) scale(0.75);
  transform: translateY(-1.375em) scale(0.75);
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline {
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline .mbsc-err-msg {
  left: 1.416667em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline .mbsc-label {
  left: 1.083334em;
  right: auto;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
  left: 4.083334em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-left .mbsc-label {
  left: 3.75em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
  left: 0.5em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-left textarea {
  padding-left: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-right textarea {
  padding-right: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
  right: 0.5em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-select input {
  padding-right: 2em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  right: 0.6875em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-select.mbsc-ic-right input {
  padding-right: 4em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  right: 3em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
  left: 0.875em;
}
.mbsc-mobiscroll.mbsc-ltr.mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  left: 2.875em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline {
  /* Floating labels */
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline .mbsc-err-msg {
  right: 1.416667em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline .mbsc-label {
  right: 1.083334em;
  left: auto;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
  right: 4.083334em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-left .mbsc-label {
  right: 3.75em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
  right: 0.5em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-left input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-left textarea {
  padding-right: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-right input,
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-right textarea {
  padding-left: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
  left: 0.5em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-select input {
  padding-left: 2em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
  left: 0.6875em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-select.mbsc-ic-right input {
  padding-left: 4em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
  left: 3em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
  right: 0.875em;
}
.mbsc-mobiscroll.mbsc-rtl.mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
  right: 2.875em;
}

.mbsc-mobiscroll {
  /* Textbox */
  /* Box Input */
  /* Outline Input */
}
.mbsc-mobiscroll.mbsc-input .mbsc-control {
  border-bottom: 1px solid #787878;
  color: #1f1f1f;
}
.mbsc-mobiscroll.mbsc-input .mbsc-control:focus, .mbsc-mobiscroll.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
  border-color: #4eccc4;
}
.mbsc-mobiscroll .mbsc-input-ic {
  color: #787878;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-input-wrap {
  background: #dedede;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-control {
  border: 1px solid #787878;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-label {
  background: #f7f7f7;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-label-inline.mbsc-input .mbsc-label, .mbsc-mobiscroll.mbsc-input-outline.mbsc-label-floating:not(.mbsc-label-floating-active).mbsc-input .mbsc-label {
  background: none;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input .mbsc-control:focus, .mbsc-mobiscroll.mbsc-input-outline.mbsc-input select:focus ~ input, .mbsc-mobiscroll.mbsc-input-outline.mbsc-err input {
  border-color: #4eccc4;
}
.mbsc-mobiscroll.mbsc-input-outline.mbsc-input textarea:focus {
  border-color: #4eccc4;
}
.mbsc-mobiscroll.mbsc-input.mbsc-err input, .mbsc-mobiscroll.mbsc-input.mbsc-err textarea {
  border-color: #de3226;
}

.mbsc-mobiscroll-dark .mbsc-fr-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.mbsc-mobiscroll-dark .mbsc-fr-w {
  background: #263238;
  color: #f7f7f7;
}
.mbsc-mobiscroll-dark .mbsc-fr-hdr,
.mbsc-mobiscroll-dark .mbsc-fr-btn {
  color: #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: rgba(79, 204, 196, 0.3);
}
.mbsc-mobiscroll-dark .mbsc-fr-arr {
  background: #263238;
}

.mbsc-mobiscroll-dark {
  /* Multiple select */
}
.mbsc-mobiscroll-dark .mbsc-sc-lbl {
  color: #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-sc-whl-l {
  border-top: 1px solid #4fccc4;
  border-bottom: 1px solid #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-sc-btn {
  color: #4fccc4;
  background: #263238;
}
.mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
.mbsc-mobiscroll-dark .mbsc-sc-itm:focus {
  background: rgba(247, 247, 247, 0.1);
}
.mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-sc-btn:hover:before, .mbsc-mobiscroll-dark.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
.mbsc-mobiscroll-dark .mbsc-sc-btn.mbsc-active:before {
  background: rgba(79, 204, 196, 0.3);
}
.mbsc-mobiscroll-dark .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  color: #4fccc4;
}

.mbsc-mobiscroll-dark {
  /* Hover, focus */
  /* Selected */
  /* Week numbers */
  /* Marks */
}
.mbsc-mobiscroll-dark .mbsc-cal-picker {
  background: #263238;
}
.mbsc-mobiscroll-dark .mbsc-cal-days > div {
  border-bottom: 1px solid #4fccc4;
  color: #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-cal-today {
  color: #4fccc4;
}
.mbsc-mobiscroll-dark.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
.mbsc-mobiscroll-dark .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
  background: rgba(79, 204, 196, 0.3);
}
.mbsc-mobiscroll-dark .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
  background: #4fccc4;
  color: #263238;
}
.mbsc-mobiscroll-dark .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
  border-color: #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-cal-week-nr {
  color: #4fccc4;
}
.mbsc-mobiscroll-dark .mbsc-cal-tab {
  border: 1px solid #4fccc4;
  color: #f7f7f7;
}
.mbsc-mobiscroll-dark .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
  background: #4fccc4;
  color: #263238;
}
.mbsc-mobiscroll-dark .mbsc-cal-mark {
  background: #f7f7f7;
}
.mbsc-mobiscroll-dark .mbsc-cal-txt {
  color: #263238;
  background: #f7f7f7;
}
.mbsc-mobiscroll-dark .mbsc-cal-icons {
  color: #f7f7f7;
}

.mbsc-gp-theme .mbsc-fr-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.mbsc-gp-theme .mbsc-fr-w {
  background: #F7F7F7;
  color: #454545;
}
.mbsc-gp-theme .mbsc-fr-hdr,
.mbsc-gp-theme .mbsc-fr-btn {
  color: #0094F7;
}
.mbsc-gp-theme .mbsc-fr-btn.mbsc-active, .mbsc-gp-theme.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: rgba(0, 148, 247, 0.3);
}
.mbsc-gp-theme .mbsc-fr-arr {
  background: #F7F7F7;
}

.mbsc-gp-theme {
  /* Multiple select */
}
.mbsc-gp-theme .mbsc-sc-lbl {
  color: #0094F7;
}
.mbsc-gp-theme .mbsc-sc-whl-l {
  border-top: 1px solid #0094F7;
  border-bottom: 1px solid #0094F7;
}
.mbsc-gp-theme .mbsc-sc-btn {
  color: #0094F7;
  background: #F7F7F7;
}
.mbsc-gp-theme.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
.mbsc-gp-theme .mbsc-sc-itm:focus {
  background: rgba(69, 69, 69, 0.1);
}
.mbsc-gp-theme.mbsc-no-touch .mbsc-sc-btn:hover:before, .mbsc-gp-theme.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
.mbsc-gp-theme .mbsc-sc-btn.mbsc-active:before {
  background: rgba(0, 148, 247, 0.3);
}
.mbsc-gp-theme .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
  color: #0094F7;
}

.mbsc-gp-theme {
  /* Hover, focus */
  /* Selected */
  /* Week numbers */
  /* Marks */
}
.mbsc-gp-theme .mbsc-cal-picker {
  background: #F7F7F7;
}
.mbsc-gp-theme .mbsc-cal-days > div {
  border-bottom: 1px solid #0094F7;
  color: #0094F7;
}
.mbsc-gp-theme .mbsc-cal-today {
  color: #0094F7;
}
.mbsc-gp-theme.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-gp-theme.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
.mbsc-gp-theme .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
  background: rgba(0, 148, 247, 0.3);
}
.mbsc-gp-theme .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
  background: #0094F7;
  color: #F7F7F7;
}
.mbsc-gp-theme .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
  border-color: #0094F7;
}
.mbsc-gp-theme .mbsc-cal-week-nr {
  color: #0094F7;
}
.mbsc-gp-theme .mbsc-cal-tab {
  border: 1px solid #0094F7;
  color: #454545;
}
.mbsc-gp-theme .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
  background: #0094F7;
  color: #F7F7F7;
}
.mbsc-gp-theme .mbsc-cal-mark {
  background: #454545;
}
.mbsc-gp-theme .mbsc-cal-txt {
  color: #F7F7F7;
  background: #454545;
}
.mbsc-gp-theme .mbsc-cal-icons {
  color: #454545;
}